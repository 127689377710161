import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ListeMComponent } from './liste-m/liste-m.component';
import { CreationMComponent } from './creation-m/creation-m.component';
import { MarqueDetailComponent } from './marque-detail/marque-detail.component';

const routes: Routes = [
  {
    path: 'Nos_marques',
    component: ListeMComponent
  },
  {
    path: 'Ajout_marque',
    component: CreationMComponent
  },
  {
    path: 'Detail_Marques',
    component: MarqueDetailComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class MarqueRoutingModule { }
 