<div class="row">
  <div class="col-sm-12">
    <div class="card">
      <div class="card-body">
        <ul
          class="nav nav-tabs profile-tabs border-bottom mb-3 d-print-none"
          aria-busy="true"
          ngbNav
          #nav="ngbNav"
          id="myTab"
          role="tablist"
        >
          <li [ngbNavItem]="1">

            <ng-template ngbNavContent >
              <div class="card border">
                <div class="card-header">
                  <div class="row">
                    <div class="col-sm-6">
                      <h5 class="mb-0">Details du véhicule</h5>
                    </div>
                    <div class="col-sm-6 text-sm-end" *ngIf="vehicule$ | async as v">
                      <h5 class="mb-0">Date création : {{v.dateCreation | date}}</h5>
                    </div>
                  </div>
                </div>
                <div class="card-body" *ngIf="vehicule$ | async as v">
                  <ul class="list-inline">
                    <li class="list-inline-item">
                      <i class="material-icons-two-tone opacity-75 me-1 f-16">calendar_today</i>
                      [{{v.marque.libelle}}]
                    </li>
                    <li class="list-inline-item">
                      <i class="material-icons-two-tone opacity-75 me-1 f-16">phone_android</i>
                      [{{v.modele.libelle}}]
                    </li>
                    <li class="list-inline-item">
                      <i class="material-icons-two-tone opacity-75 me-1 f-16">email</i>
                      [{{v.type.libelle}}]
                    </li>
                  </ul>
                  <hr class="my-4" />
                  <div class="row align-items-center">
                    <div class="col-md-4">
                      <p class="mb-1 mt-2"> <b>IMMATRICULATION PROVISOIRE :</b> {{v.immatriculationProvisoire}}</p>
                      <p><b>IMMATRICULATION DEFINITIVE:</b> {{v.immatriculationDefinitive}}</p>
                    </div>
                    <div class="col-md-4">
                      <p class="mb-1 mt-2"> <b>Etat:</b> {{v.etat}} </p>
                      <p><b>Date d'achat:</b> {{v.dateAchat}}</p>
                    </div>
                  </div>
                  <hr class="my-4" />
                  <div class="row">

                      <div class="col-md-4">
                        <h4 class="mb-3">Tarif</h4>
                        <p class="mb-1"><b>Prix d'achat :</b> {{v.tarifVehicule.prixAchat}}</p>
                        <p><b>Prix de vente :</b> {{v.tarifVehicule.prixVente}}</p>
                        <p class="mb-1"> <b>Prix de location :</b> {{v.tarifVehicule.prixLocation}} </p>
                        <p class="mb-1"><b>Montant Tva:</b> {{v.tarifVehicule.montantTva}}</p>
                        <p class="mb-1"><b>Frais douane :</b> {{v.tarifVehicule.fraisDouane}}</p>
                        <p class="mb-1"><b>Frais carte grise:</b> {{v.tarifVehicule.fraisCarteGrise}}</p>
                        <p><b>Autre Frais :</b> {{v.tarifVehicule.autreFrais}}</p>

                      </div>
                      <div class="col-md-8 row">
                        <h4 class="mb-3">Carte grise</h4>
                        <div class="col-md-4">
                          <p class="mb-1"><b>N° carte grise :</b> {{v.carteGrise.numeroCarteGrise}}</p>
                          <p><b>Date de carte grise:</b> {{v.carteGrise.dateCarteGrise}}</p>
                          <p class="mb-1"><b>Annee :</b> {{v.carteGrise.annee}}</p>
                          <p > <b>Puissance fiscale:</b> {{v.carteGrise.puissanceFiscale}}</p>
                          <p class="mb-1"> <b>Nombre d'essieux :</b> {{v.carteGrise.nombreEssieux}} </p>
                          <p class="mb-1"><b>Mise en circulation</b> {{v.carteGrise.miseEnCirculation}}</p>
                          <p class="mb-1"><b>Mode:</b> {{v.carteGrise.mode}}</p>
                          <p class="mb-1"><b>Usage :</b> {{v.carteGrise.usage}}</p>
                          <p ><b>Chassis :</b> {{v.carteGrise.chassis}}</p>

                        </div>
                        <div class="col-md-4">
                          <p class="mb-1"><b>Carrosserie :</b> {{v.carteGrise.carrosserie}}</p>
                          <p><b>Kilométrage :</b> {{v.carteGrise.kilometrage}}</p>
                          <p class="mb-1"> <b>Couleur :</b> {{v.carteGrise.couleur}} </p>
                          <p class="mb-1"><b>Nombre de porte:</b> {{v.carteGrise.nombrePortes}}</p>
                          <p class="mb-1"><b>Nombre de place:</b> {{v.carteGrise.nombrePlace}}</p>
                          <p class="mb-1"><b>Boîte à vitesse :</b> {{v.carteGrise.boiteVitesse}}</p>
                          <p><b>type de carburant :</b> {{v.carteGrise.typeCarburant}}</p>
                          <p><b>type de carburant :</b> {{v.carteGrise.typeCarburant}}</p>
                          <p ><b>Cylindrée :</b> {{v.carteGrise.cylindree}}</p>
                        </div>
                      </div>
                  </div>
                </div>
              </div>
              <div class="card border table-card">
                <div class="card-header">
                  <h5 class="mb-0">Documents</h5>
                </div>
                <div class="card-body p-0">
                  <app-details></app-details>
                </div>
                <div class="card-body m-3">
                  <ng-container *ngTemplateOutlet="totalAmount"></ng-container>
                </div>
              </div>
            </ng-template>
          </li>
        </ul>
        <div class="tab-content">
          <div [ngbNavOutlet]="nav" class="mt-2"></div>
        </div>
      </div>
    </div>
  </div>
</div>

