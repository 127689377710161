// Angular import
import { Component, Input } from '@angular/core';
import { Location } from '@angular/common';

// Project import
import { NavigationItem } from '../../navigation';
import { BerryConfig } from 'src/app/app-config';
import { CustomsThemeService } from 'src/app/theme/shared/service/customs-theme.service';

@Component({
  selector: 'app-nav-item',
  templateUrl: './nav-item.component.html',
  styleUrls: ['./nav-item.component.scss']
})
export class NavItemComponent {
  // public props
  @Input() item!: NavigationItem;
  currentLayout!: string;

  // Constructor
  constructor(
    private location: Location,
    public theme: CustomsThemeService
  ) {
    this.currentLayout = BerryConfig.layout;
  }

  ngOnInit() {
    this.theme.customMenuType.subscribe((layout: string) => {
      this.currentLayout = layout;
    });
  }

  // public method
  closeOtherMenu(event: MouseEvent) {
    if (BerryConfig.layout === 'vertical') {
      const ele = event.target as HTMLElement;
      if (ele !== null && ele !== undefined) {
        const parent = ele.parentElement;
        const up_parent = parent?.parentElement?.parentElement;
        const pre_parent = up_parent?.parentElement;
        const last_parent = up_parent?.parentElement?.parentElement?.parentElement?.parentElement;
        const sections = document.querySelectorAll('.coded-hasmenu');
        for (let i = 0; i < sections.length; i++) {
          sections[i].classList.remove('active');
          sections[i].classList.remove('coded-trigger');
        }

        if (parent?.classList.contains('coded-hasmenu')) {
          parent.classList.add('coded-trigger');
          parent.classList.add('active');
        } else if (up_parent?.classList.contains('coded-hasmenu')) {
          up_parent.classList.add('coded-trigger');
          up_parent.classList.add('active');
        } else if (pre_parent?.classList.contains('coded-hasmenu')) {
          pre_parent.classList.add('coded-trigger');
          pre_parent.classList.add('active');
        } else if (last_parent?.classList.contains('coded-hasmenu')) {
          last_parent.classList.add('coded-trigger');
          last_parent.classList.add('active');
        }
      }
    } else {
      setTimeout(() => {
        const sections = document.querySelectorAll('.coded-hasmenu');
        for (let i = 0; i < sections.length; i++) {
          sections[i].classList.remove('active');
          sections[i].classList.remove('coded-trigger');
        }

        let current_url = this.location.path();
        // eslint-disable-next-line
        // @ts-ignore
        if (this.location['_baseHref']) {
          // eslint-disable-next-line
          // @ts-ignore
          current_url = this.location['_baseHref'] + this.location.path();
        }
        const link = "a.nav-link[ href='" + current_url + "' ]";
        const ele = document.querySelector(link);
        if (ele !== null && ele !== undefined) {
          const parent = ele.parentElement;
          const up_parent = parent?.parentElement?.parentElement;
          const pre_parent = up_parent?.parentElement;
          const last_parent = up_parent?.parentElement?.parentElement?.parentElement?.parentElement;
          if (parent?.classList.contains('coded-hasmenu')) {
            parent.classList.add('active');
          } else if (up_parent?.classList.contains('coded-hasmenu')) {
            up_parent.classList.add('active');
          } else if (pre_parent?.classList.contains('coded-hasmenu')) {
            pre_parent.classList.add('active');
          } else if (last_parent?.classList.contains('coded-hasmenu')) {
            last_parent.classList.add('active');
          }
        }
      }, 500);
    }
    if ((document.querySelector('app-navigation.coded-navbar') as HTMLDivElement).classList.contains('mob-open')) {
      (document.querySelector('app-navigation.coded-navbar') as HTMLDivElement).classList.remove('mob-open');
    }
  }

  subMenuCollapse() {
    if ((document.querySelector('app-navigation.coded-navbar') as HTMLDivElement).classList.contains('coded-trigger')) {
      (document.querySelector('app-navigation.coded-navbar') as HTMLDivElement).classList.remove('coded-trigger');
    }
  }
}
