<div class="auth-main">
  <div class="auth-wrapper v3">
    <div class="auth-form">
      <div class="card mt-5">
        <div class="card-body">
          <div class="row">
            <div class="d-flex justify-content-center">
              <div class="auth-header text-center">
                <h2 class="text-secondary mt-5"><b>Mot de passe oublié ?</b></h2>
                <p class="f-16 mt-3"> Saisissez votre adresse électronique ci-dessous et nous vous enverrons l'OTP de réinitialisation du mot de passe. </p>
              </div>
            </div>
          </div>
          <div class="form-floating mb-2">
            <input type="email" class="form-control" id="floatingInput" placeholder="name@example.com" />
            <label for="floatingInput">Adresse Email / Nom d'utilisateur</label>
          </div>
          <div class="d-grid mt-4">
            <button type="button" class="btn btn-secondary p-2"> Envoyer un mail </button>
          </div>
          <hr />
          <h5 class="d-flex justify-content-center">
            <a [routerLink]="['/auth/login']"> Se connecter </a>
          </h5>
        </div>
      </div>
    </div>
  </div>
</div>
