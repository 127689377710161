import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { trigger, state, style, animate, transition } from '@angular/animations';

// project import
import { SharedModule } from 'src/app/theme/shared/shared.module';
import { ActivatedRoute, Router } from '@angular/router';
import { DocumentComponent } from './document/document.component';
import { VehiculeService } from '../services/vehicule.service';
import { VehiculeReponse } from '../reponses/vehicule.reponse';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-vehicule-detail',
  standalone: true,
  imports: [CommonModule,DocumentComponent, SharedModule],
  templateUrl: './vehicule-detail.component.html',
  styleUrl: './vehicule-detail.component.scss',
  animations: [
    trigger('fade', [
      state('in', style({ opacity: 1 })),
      transition(':enter', [style({ opacity: 0 }), animate(600)]),
      transition(':leave', animate(600, style({ opacity: 0 })))
    ])
  ]
})
export class VehiculeDetailComponent implements OnInit{
    // public props
    id: string | null = null;
    vehicule$!: Observable<VehiculeReponse | null>;


    constructor(public router: Router,private route: ActivatedRoute,private vehiculeService: VehiculeService) {}

    ngOnInit() {
      this.id = this.route.snapshot.paramMap.get('id');
      this.findVehicule();
    }


    findVehicule() {
      this.vehicule$=this.vehiculeService.getById(this.id);
    }
}
