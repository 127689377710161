<div class="row">
  <div class="col-sm-12">

        <div class="card border">
          <div class="card-header">
            <div class="row">
              <div class="col-sm-6">
                <h5 class="mb-0">Details de la vente</h5>
              </div>
              <div class="col-sm-6 text-sm-end" *ngIf="vehicule$ | async as v">
                <h5 class="mb-0">Date création : {{v.dateCreation | date}}</h5>
              </div>
            </div>
          </div>
          <div class="card-body" *ngIf="vehicule$ | async as v">

            <div class="row align-items-center">
              <div class="col-md-4">
                <p class="mb-1 mt-2"> <b>Numéro de vente :</b> {{v.numero}}</p>
                <p><b>Montant:</b> {{v.montant}}</p>
              </div>
              <div class="col-md-4">
                <p class="mb-1 mt-2 badge bg-light-success rounded f-12"> <b>Etat:</b> {{v.etat}} </p>

              </div>
            </div>
            <hr class="my-4" />
            <div class="row align-items-center">
              <div class="col-md-4">
                <p class="mb-1 mt-2"> <b>Nom :</b> {{v.client.nom}} {{v.client.prenoms}}</p>
                <p><b>Téléphone:</b> {{v.client.telephone}}</p>
                <p><b>Email:</b> {{v.client.email}}</p>
              </div>

            </div>
            <hr class="my-4" />
            <div class="table-responsive">
              <table class="table">
                <thead>
                  <tr>
                    <th>Description</th>
                    <th class="text-end">Montant</th>
                  </tr>
                </thead>
                <tbody>
                  @for (task of v.detailCommandes; track task) {
                  <tr>
                    <td>
                      <div>
                        <h5 class="mb-1">{{ task.vehicule.immatriculationProvisoire }}</h5>
                        <p class="mb-0 text-muted">{{v.client.nom}} {{v.client.prenoms}}</p>
                      </div>
                    </td>
                    <td class="text-end">{{ task.prixUnitaire }}</td>
                  </tr>
                  }
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>

