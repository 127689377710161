import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { SessionService } from 'src/app/Pages/auth/services/session.service';
import { ProfilModele } from '../modeles/profil.modele';
import { MenuReponse, ProfilReponse } from '../reponses/profil.reponse';


@Injectable({
  providedIn: 'root'
})
export class ProfilService {
  private url: string = `${environment.BaseUrl}`;
  private key: string = `${environment.key}`;
  constructor(private http: HttpClient, private localStorageService: SessionService) { }
  private token: string = 'Bearer ' + this.localStorageService.getData('token');
  // Définition des options HTTP avec en-têtes
  private getHttpOptions(): { headers: HttpHeaders } {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept-Language': 'fr-FR',
        'Authorization': this.token,
        //'X-KEY': this.key,
      }),
    };
  }

  create(payload: ProfilModele): Observable<ProfilReponse> {
    return this.http.post<any>(`${this.url}/profils`, payload, this.getHttpOptions());
  }

  update(payload: ProfilModele, id: any): Observable<ProfilReponse> {
    return this.http.put<any>(`${this.url}/profils/${id}`, payload, this.getHttpOptions());
  }

  delete(id: any): Observable<ProfilReponse> {
    return this.http.delete<any>(`${this.url}/profils/${id}`, this.getHttpOptions());
  }

  getById(id: any): Observable<ProfilReponse> {
    return this.http.get<any>(`${this.url}/profils/${id}`, this.getHttpOptions());
  }

  getAll(query?: any): Observable<ProfilReponse[]> {
    const url = query ? `${this.url}/profils?${query}` : `${this.url}/profils`;
    return this.http.get<any>(url, this.getHttpOptions());
  }
  getAllMenu(query?: any): Observable<MenuReponse[]> {
    const url = query ? `${this.url}/menus?${query}` : `${this.url}/menus`;
    return this.http.get<any>(url, this.getHttpOptions());
  }

}
