export class DetailCommande {
  constructor(
    public prixUnitaire: any,
    public quantite: any,
    public idVehicule: any,
    public dateDebut: any=null,
    public dateFin: any=null,
    public idChauffeur: any=null,
    public montantChauffeur: any=null,
    public villeArrivee: any=null,
    public villeDepart: any=null
  ) {}
}

export class VenteVehiculeModele {
  constructor(
    public montant: any,
    public type: any,
    public reste: any,
    public idClient: any,
    public tva: any,
    public montantRemise: any,
    public detailCommandes: DetailCommande[]
  ) {}
}
