<div class="row">
  <div class="col-sm-12">

    <div class="card border">
      <div class="card-header">
        <div class="row">
          <div class="col-sm-6">
            <h5 class="mb-0">Details de la location</h5>
          </div>
          <div class="col-sm-6 text-sm-end" *ngIf="vehicule$ | async as v">
            <h5 class="mb-0">Date création : {{v.dateCreation | date}}</h5>
          </div>
        </div>
      </div>
      <div class="card-body" *ngIf="vehicule$ | async as v">

        <div class="row align-items-center">
          <div class="col-md-6">
            <p class="mb-1 mt-2"> <b>Numéro de location :</b> {{v.numero}}</p>
            <p><b>Montant:</b> {{v.montant}}</p>
            <p><b>Montant à payer:</b> {{v.montant-(v.montantRemise+(v.montant*(v.tva/100)))}}</p>
          </div>

          <div class="col-md-6 text-md-end">
            <p class="mb-1 mt-2 badge bg-light-success rounded f-12"> <b>Etat:</b> {{v.etat}} </p>

          </div>
        </div>

        <hr class="my-4" />
        <div class="row align-items-center">
          <div class="col-md-6">
            <p><b>Client</b></p>
            <p class="mb-1 mt-2"> <b>Nom :</b> {{v.client.nom}} {{v.client.prenoms}}</p>
            <p><b>Téléphone:</b> {{v.client.telephone}}</p>
            <p><b>Email:</b> {{v.client.email}}</p>
          </div>
          <div class="col-md-6">
            <p class="mb-1 mt-2"> <b>Remise :</b> {{v.montantRemise}}</p>
            <p class="mb-1 mt-2"> <b>Tva :</b> {{v.tva}}</p>
          </div>
        </div>
        <hr class="my-4" />
        <div *ngIf="hasSuccess" class="alert alert-success mt-2 mb-2">
          <p>{{successMessage}}</p>
        </div>
        <div *ngIf="hasError" class="alert alert-danger mt-2 mb-2">
          <p>{{errorMessage}}</p>
        </div>
        <form [formGroup]="venteForm" (ngSubmit)="onSubmit()">
          <div *ngIf="v.detailCommandes[0].driver=='Avec chauffeur' && v.etat!='ACTIVATED' " class="row">
            <div class="col-sm-6">
              <div class="form-group">
                <label for="cchauffeur">Chauffeur</label>
                <ng-select [selectOnTab]="true" #chauffeurSelect [items]="activatedChauffeurs" [selectOnTab]="true"
                  (change)="selectChauffeur($event)" bindValue="idChauffeur" labelForId="cchauffeur"
                  formControlName="idChauffeur">
                  <!-- Template pour le label affiché dans le champ sélectionné -->
                  <ng-template ng-label-tmp let-item="item">
                    {{ item.nom }} {{ item.prenoms }} {{ item.telephone }}
                  </ng-template>

                  <!-- Template pour les options dans la liste déroulante -->
                  <ng-template ng-option-tmp let-item="item">
                    {{ item.nom }} {{ item.prenoms }} {{ item.telephone }}
                  </ng-template>

                </ng-select>
              </div>
            </div>
            <div class="col-sm-6">
              <div class="form-group">
                <label for="montantChauffeur">Montant chauffeur</label>
                <input type="number" class="form-control" formControlName="montantChauffeur" />
              </div>
            </div>
          </div>

          <div class="table-responsive">
            <table class="table">
              <thead>
                <tr>
                  <th>Description</th>
                  <th>Depart</th>
                  <th>Destination</th>
                  <th>Date de sortie</th>
                  <th>Date de retour</th>
                  <th class="text-end">Perdieme</th>
                  <th class="text-end">Montant</th>
                </tr>
              </thead>
              <tbody>
                @for (task of v.detailCommandes; track task) {
                <tr>
                  <td>
                    <div>
                      <h5 class="mb-1">{{ task.vehicule.immatriculationProvisoire }}</h5>
                      <p *ngIf="task.chauffeur" class="mb-0 text-muted">{{task.chauffeur.nom}}
                        {{task.chauffeur.prenoms}}-
                        {{task.chauffeur.telephone}}</p>
                    </div>
                  </td>
                  <td>{{ task.villeDepart }}</td>
                  <td>{{ task.villeArrivee }}</td>
                  <td>{{ task.dateDebut }}</td>
                  <td>{{ task.dateFin }}</td>
                  <td class="text-end">{{ task.montantChauffeur }}</td>
                  <td class="text-end">{{ task.prixUnitaire }}</td>
                </tr>
                }
              </tbody>
            </table>
          </div>
          <div class="d-flex justify-content-end my-3">
            <button *ngIf="isLoading" class="btn btn-primary" type="submit" disabled>
              <span class="spinner-grow spinner-grow-sm" role="status"></span>
              Traitement...
            </button>

            <!-- Bouton "Enregistrer" -->
            <button type="submit" class="btn btn-primary" [disabled]="venteForm.invalid" *ngIf="!isLoading">
              Valider
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>