<div class="row">
  <!-- [ sample-page ] start -->
  <div class="row">
    <div class="col-md-12">
      <div *ngIf="hasSuccess" class="alert alert-success mt-2 mb-2">
        <p>{{successMessage}}</p>
      </div>
      <div *ngIf="hasError" class="alert alert-danger mt-2 mb-2">
        <p>{{errorMessage}}</p>
      </div>
      <form [formGroup]="maintenanceForm" (ngSubmit)="onSubmit()">
        <app-card cardTitle="Création Maintenance">
          <div class="row">
            <div class="col-sm-6">
              <div class="form-group">
                <label for="idCompagnieAssurance">Référence au véhicule<span class="text-danger">*</span></label>
                <select type="text" formControlName="idVehicule" class="form-control" [ngClass]="{
                                      'is-invalid':
                                        maintenanceForm.get('idVehicule')?.invalid &&
                                        maintenanceForm.get('idVehicule')?.touched
                                    }">
                  <option value="">Sélectionner</option>
                  <option *ngFor="let vehicule of vehicules$ | async" [value]="vehicule.idVehicule">
                    {{ vehicule.immatriculationProvisoire }} {{ vehicule.modele.libelle }} {{ vehicule.marque.libelle }}
                  </option>
                </select>
                <div
                  *ngIf="maintenanceForm.get('idVehicule')?.errors?.['required'] && maintenanceForm.get('idVehicule')?.touched"
                  class="invalid-feedback">
                  Le vehicule est requis.
                </div>

              </div>
              <div class="form-group">
                <label class="form-label" for="dateFin">Date début de la police d'assurance<span
                    class="text-danger">*</span></label>
                <input type="date" id="dateDebut" formControlName="dateDebut" class="form-control" required />
                <div
                  *ngIf="maintenanceForm.get('dateDebut')?.errors?.['required'] && maintenanceForm.get('dateDebut')?.touched"
                  class="invalid-feedback">
                  La date debut est requise.
                </div>

              </div>
              <div class="form-group">
                <label class="form-label" for="numeroPolice">Numéro de la police d'assurance <span
                    class="text-danger">*</span></label>
                <input type="text" id="numeroPolice" formControlName="numeroPolice" class="form-control" required />
                <div
                  *ngIf="maintenanceForm.get('numeroPolice')?.errors?.['required'] && maintenanceForm.get('numeroPolice')?.touched"
                  class="invalid-feedback">
                  Le numéro de la police est requise.
                </div>
              </div>
            </div>
            <div class="col-sm-6">
              <div class="form-group">
                <label for="idCompagnieAssurance">Nom de la compagnie d'assurance<span
                    class="text-danger">*</span></label>
                <select type="text" formControlName="idCompagnieAssurance" class="form-control" [ngClass]="{
                                                          'is-invalid':
                                                            maintenanceForm.get('idCompagnieAssurance')?.invalid &&
                                                            maintenanceForm.get('idCompagnieAssurance')?.touched
                                                        }">
                  <option value="">Sélectionner</option>
                  <option *ngFor="let compagnie of compagnies$ | async" [value]="compagnie.idCompagnieAssurance">
                    {{ compagnie.nom }}
                  </option>
                </select>
                <div
                  *ngIf="maintenanceForm.get('idCompagnieAssurance')?.errors?.['required'] && maintenanceForm.get('idCompagnieAssurance')?.touched"
                  class="invalid-feedback">
                  La compagnie est requise.
                </div>

              </div>
              <div class="form-group">
                <label class="form-label" for="dateDebut">Date de fin de la police d'assurance<span
                    class="text-danger">*</span></label>
                <input type="date" id="dateFin" formControlName="dateFin" class="form-control" required />
                <div
                  *ngIf="maintenanceForm.get('dateFin')?.errors?.['required'] && maintenanceForm.get('dateFin')?.touched"
                  class="invalid-feedback">
                  La date fin est requise.
                </div>
              </div>
              <div class="form-group">
                <label class="form-label" for="codeAssurance">Numéro de l'assurance <span
                    class="text-danger">*</span></label>
                <input type="text" id="codeAssurance" formControlName="codeAssurance" class="form-control" required />
                <div
                  *ngIf="maintenanceForm.get('codeAssurance')?.errors?.['required'] && maintenanceForm.get('codeAssurance')?.touched"
                  class="invalid-feedback">
                  Le numéro de l'assurance est requis.
                </div>
              </div>
            </div>

            <div class="clo-sm-12">
              <div class="form-group">
                <label class="form-label" for="fileAssurance">Fichier de l'assurance <span
                    class="text-danger">*</span></label>
                <input type="file" id="lien" formControlName="lien" (change)="fileChangeEvent($event)"
                  class="form-control" required />
                <div *ngIf="maintenanceForm.get('lien')?.errors?.['required'] && maintenanceForm.get('lien')?.touched"
                  class="invalid-feedback">
                  Le fichier de l'assurance est requis.
                </div>
              </div>
            </div>
            <div class="card-footer text-end">
              <div class="d-flex justify-content-end my-3">
                <button *ngIf="isLoading" class="btn btn-primary" type="submit" disabled>
                  <span class="spinner-grow spinner-grow-sm" role="status"></span>
                  Traitement...
                </button>

                <!-- Bouton "Enregistrer" -->
                <button type="submit" class="btn btn-primary" [disabled]="maintenanceForm.invalid" *ngIf="!isLoading">
                  Enregistrer
                </button>
              </div>
            </div>
          </div>
        </app-card>

      </form>
    </div>
  </div>
  <!-- [ sample-page ] end -->
</div>