import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CreationVenteVehiculeComponent } from './vente-vehicule/creation/creation.vente.vehicule.component';
import { ListeVenteVehiculeComponent } from './vente-vehicule/liste/liste.vente.vehicule.component';
import { CreationLocationVehiculeComponent } from './location/creation/creation.location.vehicule.component';
import { ListeLocationVehiculeComponent } from './location/liste/liste.location.vehicule.component';
import { VenteVehiculeDetailComponent } from './vente-vehicule/detail/vente-vehicule-detail.component';
import { LocationVehiculeDetailComponent } from './location/detail/location-vehicule-detail.component';

const routes: Routes = [

  {
    path: 'creation-vente-vehicule',
    component: CreationVenteVehiculeComponent
  },
  {
    path: 'liste-vente-vehicule',
    component: ListeVenteVehiculeComponent
  },
  {
    path: 'creation-location-vehicule',
    component: CreationLocationVehiculeComponent
  },
  {
    path: 'liste-location-vehicule',
    component: ListeLocationVehiculeComponent
  },
  {
    path: 'vente-vehicule-detail/:id',
    component: VenteVehiculeDetailComponent
  },
  {
    path: 'location-vehicule-detail/:id',
    component: LocationVehiculeDetailComponent
  },


];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class GestionLocationVenteRoutingModule { }
