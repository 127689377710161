<div class="row">
    <!-- [ sample-page ] start -->
    <div class="col-sm-12">
      <div class="card table-card">
        <div class="card-header">
          <div class="row align-items-center g-2">
            <div class="col">
              <h5>Liste de maintenance</h5>
            </div>
            <div class="col-auto">
              <div class="form-search">
                <i class="ti ti-search"></i>
                <input type="search" class="form-control" placeholder="Search Followers" />
              </div>
            </div>
          </div>
        </div>
        <div class="card-body">
          <div class="table-responsive">
            <table class="table table-hover">
              <thead>
                <tr class="text-center">
                  <th>#</th>
                  <th>Référence au véhicule</th>
                  <th>Date du service</th>
                  <th>Coût du service</th>
                  <th>Date prévue pour le prochain service</th>
                  <th>service_type</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                @for (task of PersonList; track task) {
                  <tr class="text-center">
                    <td>{{ task.number }}</td>
                    <td>{{ task.name }}</td>
                    <td>{{ task.country }}</td> 
                    <td>{{ task.friend }}</td>
                    <td>{{ task.follower }}</td>
                    <td class="text-center">
                      <span class="badge {{ task.color }} rounded-pill f-14">{{ task.status }}</span>
                    </td>
                    <td class="text-center">
                      <a class="btn btn-link-primary"  (click)="openLg(content6)" title="Voir">
                        <i class="ti ti-eye"></i>
                    </a>
                      <button type="button" class="btn btn-link-primary" title="Editer" >
                        <i class="ti ti-pencil"></i>
                      </button>
                      <button type="button" class="btn btn-link-danger" title="Delete">
                        <i class="ti ti-ban"></i>
                      </button>
                    </td>
                  </tr>
                }
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <!-- [ sample-page ] end -->
  </div>
  <div class="col-xl-4 col-md-6">
      <ng-template #content6 let-modal>
        <div class="modal-header">
          <button type="button" class="btn-close" aria-label="Close" (click)="modal.close('Close click')"></button>
        </div>
        <div class="modal-body">
          <app-maintenance-detail></app-maintenance-detail>
        </div>
      </ng-template>
  </div>