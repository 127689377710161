import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

import { SessionService } from 'src/app/Pages/auth/services/session.service';
import { VenteVehiculeModele } from '../modeles/vente.vehicule.modele';
import { VenteVehiculeReponse } from '../reponse/vente.vehicule.reponse';

@Injectable({
  providedIn: 'root',
})
export class VenteVehiculeService {
  private url: string = `${environment.BaseUrl}`;
  private key: string = `${environment.key}`;
  constructor(private http: HttpClient, private localStorageService: SessionService) { }
  private token: string = 'Bearer ' + this.localStorageService.getData('token');
  // Définition des options HTTP avec en-têtes
  private getHttpOptions(): { headers: HttpHeaders } {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept-Language': 'fr-FR',
        'Authorization': this.token,
        //'X-KEY': this.key,
      }),
    };
  }

  create(payload: VenteVehiculeModele): Observable<VenteVehiculeReponse> {
    return this.http.post<any>(
      `${this.url}/commandes`,
      payload,
      this.getHttpOptions()
    );
  }

  updateState(id: any, payload: VenteVehiculeModele): Observable<VenteVehiculeReponse> {
    return this.http.put<any>(
      `${this.url}/commandes/workflow/${id}`,
      payload,
      this.getHttpOptions()
    );
  }
  update(payload: VenteVehiculeModele, id: any): Observable<VenteVehiculeReponse> {
    return this.http.put<any>(
      `${this.url}/commandes/${id}`,
      payload,
      this.getHttpOptions()
    );
  }

  updateForModification(id: any, category: any): Observable<VenteVehiculeReponse> {
    return this.http.put<any>(
      `${this.url}/commandes/workflow/${id}/category/${category}`,
      null,
      this.getHttpOptions()
    );
  }

  updateProformaToFacturation(id: any): Observable<VenteVehiculeReponse> {
    return this.http.put<any>(
      `${this.url}/commandes/proforma/${id}/to/facturation`,
      null,
      this.getHttpOptions()
    );
  }
  delete(id: any): Observable<VenteVehiculeReponse> {
    return this.http.delete<any>(
      `${this.url}/commandes/${id}`,
      this.getHttpOptions()
    );
  }

  getById(id: any): Observable<VenteVehiculeReponse> {
    return this.http.get<any>(`${this.url}/commandes/${id}`, this.getHttpOptions());
  }
  getForModificationById(id: any, category: any): Observable<VenteVehiculeReponse> {
    return this.http.get<any>(`${this.url}/commandes/modifier/${id}/category/${category}`, this.getHttpOptions());
  }

  getAll(query?: any): Observable<VenteVehiculeReponse[]> {
    const url = query ? `${this.url}/commandes?${query}` : `${this.url}/commandes`;
    return this.http.get<any>(
      url,
      this.getHttpOptions()
    );
  }
}
