import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { GestionChauffeurRoutingModule } from './gestion-chauffeur-routing.module';


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    GestionChauffeurRoutingModule
  ]
})
export class GestionChauffeurModule { }
