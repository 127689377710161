import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ListeRechargeComponent } from './liste-recharge/liste-recharge.component';
import { CreationFicheRechargeComponent } from './creation-fiche-recharge/creation-fiche-recharge.component';
import { DetailFicheComponent } from './detail-fiche/detail-fiche.component';

const routes: Routes = [
  {
    path: 'liste-rechargerment',
    component: ListeRechargeComponent
  },
  {
    path: 'Fiche-de-Carburant',
    component: CreationFicheRechargeComponent
  },
  {
    path: 'Detail-rechargement',
    component: DetailFicheComponent
  }

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class GestionCarburantRoutingModule { }
