// Angular Imports
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
// project import
import { AdminComponent } from './theme/layout/admin/admin.component';
import { GuestComponent } from './theme/layout/guest/guest.component';

const routes: Routes = [
  {
    path: 'app',
    component: AdminComponent,
    children: [
      {
        path: 'dashboard',
        loadChildren: () =>
          import('./Pages/dashboard/dashboard.module').then(
            (m) => m.DashboardModule
          ),
      },
      {
        path: 'gestion-location-vente',
        loadChildren: () =>
          import('./Pages/gestion-location-vente/gestion-location-vente.module').then(
            (m) => m.GestionLocationVenteModule
          ),
      },
      {
        path: 'gestion-partenaire',
        loadChildren: () =>
          import('./Pages/gestion-partenaire/gestion-patenaire.module').then(
            (m) => m.GestionPartenaireModule
          ),
      },
      {
        path: 'gestion-vehicule',
        loadChildren: () =>
          import('./Pages/gestion-vehicule/gestion-vehicule.module').then(
            (m) => m.GestionVehiculeModule
          ),
      },
      {
        path: 'gestion-chauffeur',
        loadChildren: () =>
          import('./Pages/gestion-chauffeur/gestion-chauffeur.module').then(
            (m) => m.GestionChauffeurModule
          ),
      },
      {
        path: 'gestion-carburant',
        loadChildren: () =>
          import('./Pages/gestion-carburant/gestion-carburant.module').then(
            (m) => m.GestionCarburantModule
          ),
      },
      {
        path: 'gestion-maintenance',
        loadChildren: () =>
          import('./Pages/gestion-maintenance/gestion-maintenance.module').then(
            (m) => m.GestionMaintenanceModule
          ),
      },
      {
        path: 'gestion-visite',
        loadChildren: () =>
          import('./Pages/gestion-visite/gestion-visite.module').then(
            (m) => m.GestionVisiteModule
          ),
      },
      {
        path: 'gestion-documents',
        loadChildren: () =>
          import('./Pages/gestion-document/gestion-document.module').then(
            (m) => m.GestionDocumentModule
          ),
      },
      {
        path: 'gestion-assurance',
        loadChildren: () =>
          import('./Pages/gestion-assurance/gestion-assurance.module').then(
            (m) => m.GestionAssuranceModule
          ),
      },
    ],
  },

  {
    path: 'auth',
    component: GuestComponent,
    loadChildren: () =>
      import('./Pages/auth/authentication.module').then(
        (m) => m.AuthenticationModule
      ),
  },
  { path: '', redirectTo: 'auth/login', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
