import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { SessionService } from 'src/app/Pages/auth/services/session.service';
import { VisiteModele } from '../modeles/visite.modele';
import { VisiteReponse } from '../reponse/visite.reponse';

@Injectable({
  providedIn: 'root',
})
export class VisiteService {
  private url: string = `${environment.BaseUrl}`;
  private key: string = `${environment.key}`;
  constructor(private http: HttpClient, private localStorageService: SessionService) { }
  private token: string = 'Bearer ' + this.localStorageService.getData('token');
  // Définition des options HTTP avec en-têtes
  private getHttpOptions(): { headers: HttpHeaders } {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept-Language': 'fr-FR',
        'Authorization': this.token,
        //'X-KEY': this.key,
      }),
    };
  }

  create(payload: VisiteModele): Observable<VisiteReponse> {
    return this.http.post<any>(
      `${this.url}/visites`,
      payload,
      this.getHttpOptions()
    );
  }

  update(payload: VisiteModele, id: any): Observable<VisiteReponse> {
    return this.http.put<any>(
      `${this.url}/visites/${id}`,
      payload,
      this.getHttpOptions()
    );
  }

  delete(id: any): Observable<VisiteReponse> {
    return this.http.delete<any>(
      `${this.url}/visites/${id}`,
      this.getHttpOptions()
    );
  }

  getById(id: any): Observable<VisiteReponse> {
    return this.http.get<any>(`${this.url}/visites/${id}`, this.getHttpOptions());
  }

  getAll(query?: any): Observable<VisiteReponse[]> {
    const url = query ? `${this.url}/visites?${query}` : `${this.url}/visites`;
    return this.http.get<any>(
      url,
      this.getHttpOptions()
    );
  }
}
