<div class="row">
  <div class="col-sm-12">
    <div class="card table-card">
      <div class="card-header">
        <div class="d-flex align-items-center justify-content-between">
          <h5>Creation de nouveau vehicule</h5>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-sm-12">
      <app-card cardTitle="Vehicule">
        <h5>Enregistremment</h5>
        <hr />
        <div *ngIf="hasSuccess" class="alert alert-success mt-2 mb-2">
          <p>{{successMessage}}</p>
        </div>
        <div *ngIf="hasError" class="alert alert-danger mt-2 mb-2">
          <p>{{errorMessage}}</p>
        </div>
        <form [formGroup]="vehiculeForm" (ngSubmit)="onSubmit()">
          <div class="row" *ngIf="formStep === 0">
            <div class="col-md-6">
              <div class="form-group">
                <label for="marque">Marque du véhicule<span class="text-danger">*</span></label>
                <select
                  formControlName="idMarque"
                  class="form-select"
                  [ngClass]="{
                    'is-invalid':
                      vehiculeForm.get('idMarque')?.invalid &&
                      vehiculeForm.get('idMarque')?.touched
                  }"
                >
                  <option value="">Sélectionner</option>
                  <option *ngFor="let marque of marques" [value]="marque.idMarque">
                    {{ marque.libelle }}
                  </option>
                </select>
                <div
                  *ngIf="vehiculeForm.get('idMarque')?.errors?.['required'] && vehiculeForm.get('idMarque')?.touched"
                  class="invalid-feedback"
                >
                  La marque est requise.
                </div>
              </div>

              <!-- Modèle -->
              <div class="form-group">
                <label for="modele">Modèle du véhicule<span class="text-danger">*</span></label>
                <select
                  type="text"
                  formControlName="idModele"
                  class="form-control"
                  [ngClass]="{
                    'is-invalid':
                      vehiculeForm.get('idModele')?.invalid &&
                      vehiculeForm.get('idModele')?.touched
                  }"
                >
                <option value="">Sélectionner</option>
                <option *ngFor="let modele of modeles" [value]="modele.idModele">
                  {{ modele.libelle }}
                </option>
              </select>
                <div
                  *ngIf="vehiculeForm.get('idModele')?.errors?.['required'] && vehiculeForm.get('idModele')?.touched"
                  class="invalid-feedback"
                >
                  Le modèle est requis.
                </div>

              </div>

              <!-- Type du véhicule -->
              <div class="form-group">
                <label for="typeVehicule">Type du véhicule<span class="text-danger">*</span></label>
                <select
                  formControlName="idType"
                  class="form-select"
                  [ngClass]="{
                    'is-invalid':
                      vehiculeForm.get('idType')?.invalid &&
                      vehiculeForm.get('idType')?.touched
                  }"
                >
                  <option value="">Sélectionner</option>
                  <option *ngFor="let type of types" [value]="type.idType">
                    {{ type.libelle }}
                  </option>
                </select>
                <div
                  *ngIf="vehiculeForm.get('idType')?.errors?.['required'] && vehiculeForm.get('idType')?.touched"
                  class="invalid-feedback"
                >
                  Le type de véhicule est requis.
                </div>
              </div>
              <!-- Fournisseur -->
              <div class="form-group">
                <label for="fournisseur">Fournisseur<span class="text-danger">*</span></label>
                <select
                  formControlName="idFournisseur"
                  class="form-select"
                  [ngClass]="{
                    'is-invalid':
                      vehiculeForm.get('idFournisseur')?.invalid &&
                      vehiculeForm.get('idFournisseur')?.touched
                  }"
                >
                  <option value="">Sélectionner</option>
                  <option *ngFor="let fournisseur of fournisseurs" [value]="fournisseur.idFournisseur">
                    {{ fournisseur.nom+' '+fournisseur.prenoms }}
                  </option>
                </select>
                <div
                  *ngIf="vehiculeForm.get('idFournisseur')?.errors?.['required'] && vehiculeForm.get('idFournisseur')?.touched"
                  class="invalid-feedback"
                >
                  Le Fournisseur est requis.
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <!-- Immatriculation Provisoire -->
              <div class="form-group">
                <label for="immatriculationProvisoire"
                  >Immatriculation provisoire<span class="text-danger">*</span></label
                >
                <input
                  type="text"
                  formControlName="immatriculationProvisoire"
                  class="form-control"
                  [ngClass]="{
                    'is-invalid':
                      vehiculeForm.get('immatriculationProvisoire')?.invalid &&
                      vehiculeForm.get('immatriculationProvisoire')?.touched
                  }"
                />
                <div
                  *ngIf="vehiculeForm.get('immatriculationProvisoire')?.errors?.['required'] && vehiculeForm.get('immatriculationProvisoire')?.touched"
                  class="invalid-feedback"
                >
                  L'immatriculation provisoire est requise.
                </div>
                <div
                  *ngIf="vehiculeForm.get('immatriculationProvisoire')?.errors?.['minlength'] && vehiculeForm.get('immatriculationProvisoire')?.touched"
                  class="invalid-feedback"
                >
                  Elle doit contenir au moins 3 caractères.
                </div>
              </div>
              <!-- Immatriculation Définitive -->
              <div class="form-group">
                <label for="immatriculationDefinitive"
                  >Immatriculation Définitive<span class="text-danger">*</span></label
                >
                <input
                  type="text"
                  formControlName="immatriculationDefinitive"
                  class="form-control"
                  [ngClass]="{
                    'is-invalid':
                      vehiculeForm.get('immatriculationDefinitive')?.invalid &&
                      vehiculeForm.get('immatriculationDefinitive')?.touched
                  }"
                />
                <div
                  *ngIf="vehiculeForm.get('immatriculationDefinitive')?.errors?.['required'] && vehiculeForm.get('immatriculationDefinitive')?.touched"
                  class="invalid-feedback"
                >
                  L'immatriculation définitive est requise.
                </div>
                <div
                  *ngIf="vehiculeForm.get('immatriculationDefinitive')?.errors?.['minlength'] && vehiculeForm.get('immatriculationDefinitive')?.touched"
                  class="invalid-feedback"
                >
                  Elle doit contenir au moins 3 caractères.
                </div>
              </div>
              <!-- Catégorie -->
              <div class="form-group">
                <label for="categorie">Catégorie<span class="text-danger">*</span></label>
                <select
                  formControlName="categorie"
                  class="form-select"
                  [ngClass]="{
                    'is-invalid':
                      vehiculeForm.get('categorie')?.invalid &&
                      vehiculeForm.get('categorie')?.touched
                  }"
                >
                  <option value="">Sélectionner</option>
                  <option
                    *ngFor="let categorie of categories"
                    [value]="categorie.id"
                  >
                    {{ categorie.libelle }}
                  </option>
                </select>
                <div
                  *ngIf="vehiculeForm.get('categorie')?.errors?.['required'] && vehiculeForm.get('categorie')?.touched"
                  class="invalid-feedback"
                >
                  La catégorie est requise.
                </div>
              </div>
            </div>
          </div>

          <!-- Étape suivante -->
          <div class="row" *ngIf="formStep === 1">
            <div class="col-md-6">
              <!-- Année de fabrication -->
              <div class="form-group">
                <label for="anneeFabrication">Année de fabrication<span class="text-danger">*</span></label>
                <input
                  type="number"
                  min="1900"
                  max="2100"
                  step="1"
                  formControlName="anneeFabrication"
                  class="form-control"
                  [ngClass]="{
                    'is-invalid':
                      vehiculeForm.get('anneeFabrication')?.invalid &&
                      vehiculeForm.get('anneeFabrication')?.touched
                  }"
                  placeholder="Année"
                />
                <div
                  *ngIf="vehiculeForm.get('anneeFabrication')?.errors?.['required'] && vehiculeForm.get('anneeFabrication')?.touched"
                  class="invalid-feedback"
                >
                  L'année de fabrication est requise.
                </div>
                <div
                  *ngIf="vehiculeForm.get('anneeFabrication')?.errors?.['min'] || vehiculeForm.get('anneeFabrication')?.errors?.['max']"
                  class="invalid-feedback"
                >
                  L'année doit être comprise entre 1900 et l'année actuelle.
                </div>
              </div>

              <!-- Kilométrage -->
              <div class="form-group">
                <label for="kilometrage">Kilométrage<span class="text-danger">*</span></label>
                <input
                  type="text"
                  formControlName="kilometrage"
                  class="form-control"
                  [ngClass]="{
                    'is-invalid':
                      vehiculeForm.get('kilometrage')?.invalid &&
                      vehiculeForm.get('kilometrage')?.touched
                  }"
                />
                <div
                  *ngIf="vehiculeForm.get('kilometrage')?.errors?.['required'] && vehiculeForm.get('kilometrage')?.touched"
                  class="invalid-feedback"
                >
                  Le kilométrage est requis.
                </div>
                <div
                  *ngIf="vehiculeForm.get('kilometrage')?.errors?.['pattern'] && vehiculeForm.get('kilometrage')?.touched"
                  class="invalid-feedback"
                >
                  Le kilométrage doit être un nombre valide.
                </div>
              </div>

              <!-- Couleur -->
              <div class="form-group">
                <label for="couleur">Couleur</label>
                <input
                  type="text"
                  formControlName="couleur"
                  class="form-control"
                />
              </div>

              <!-- Nombre de portes -->
              <div class="form-group">
                <label for="nombrePorte">Nombre de portes</label>
                <input
                  type="number"
                  min="2"
                  formControlName="nombrePorte"
                  class="form-control"
                />
              </div>
            </div>

            <div class="col-md-6">
              <!-- Nombre de places -->
              <div class="form-group">
                <label for="nombrePlace">Nombre de places</label>
                <input
                  type="number"
                  min="2"
                  formControlName="nombrePlace"
                  class="form-control"
                />
              </div>

              <!-- Boîte de vitesse -->
              <div class="form-group">
                <label for="boiteVitesse">Boîte de vitesse<span class="text-danger">*</span></label>
                <input
                  type="text"
                  formControlName="boiteVitesse"
                  class="form-control"
                  [ngClass]="{
                    'is-invalid':
                      vehiculeForm.get('boiteVitesse')?.invalid &&
                      vehiculeForm.get('boiteVitesse')?.touched
                  }"
                />
                <div
                  *ngIf="vehiculeForm.get('boiteVitesse')?.errors?.['required'] && vehiculeForm.get('boiteVitesse')?.touched"
                  class="invalid-feedback"
                >
                  La boîte de vitesse est requise.
                </div>
              </div>

              <!-- Type de carburant (sélecteur) -->
              <div class="form-group">
                <label for="carburant">Type de carburant<span class="text-danger">*</span></label>
                <select
                  formControlName="typeCarburant"
                  class="form-select"
                  [ngClass]="{
                    'is-invalid':
                      vehiculeForm.get('typeCarburant')?.invalid &&
                      vehiculeForm.get('typeCarburant')?.touched
                  }"
                >
                  <option value="">Sélectionner</option>
                  <option value="Essence">Essence</option>
                  <option value="Diesel">Diesel</option>
                  <option value="Électrique">Électrique</option>
                  <option value="Hybride">Hybride</option>
                </select>
                <div
                  *ngIf="vehiculeForm.get('typeCarburant')?.errors?.['required'] && vehiculeForm.get('typeCarburant')?.touched"
                  class="invalid-feedback"
                >
                  Le type de carburant est requis.
                </div>
              </div>

              <!-- Carrosserie -->
              <div class="form-group">
                <label for="carrosserie">Carrosserie</label>
                <input
                  type="text"
                  formControlName="carrosserie"
                  class="form-control"
                />
              </div>
            </div>
          </div>

          <div class="row" *ngIf="formStep === 2">
            <div class="col-md-6">
              <!-- Numéro de la carte grise -->
              <div class="form-group">
                <label for="numeroCarteGrise">Numéro de la carte grise<span class="text-danger">*</span></label>
                <input
                  type="text"
                  formControlName="numeroCarteGrise"
                  class="form-control"
                  [ngClass]="{
                    'is-invalid':
                      vehiculeForm.get('numeroCarteGrise')?.invalid &&
                      vehiculeForm.get('numeroCarteGrise')?.touched
                  }"
                  placeholder="Numéro de la carte grise"
                />
                <div
                  *ngIf="vehiculeForm.get('numeroCarteGrise')?.errors?.['required'] && vehiculeForm.get('numeroCarteGrise')?.touched"
                  class="invalid-feedback"
                >
                  Le numéro de la carte grise est requis.
                </div>
              </div>

              <!-- Châssis -->
              <div class="form-group">
                <label for="chassis">Châssis<span class="text-danger">*</span></label>
                <input
                  type="text"
                  formControlName="chassis"
                  class="form-control"
                  [ngClass]="{
                    'is-invalid':
                      vehiculeForm.get('chassis')?.invalid &&
                      vehiculeForm.get('chassis')?.touched
                  }"
                  placeholder="Numéro de châssis"
                />
                <div
                  *ngIf="vehiculeForm.get('chassis')?.errors?.['required'] && vehiculeForm.get('chassis')?.touched"
                  class="invalid-feedback"
                >
                  Le numéro de châssis est requis.
                </div>
              </div>

              <!-- Cylindrée -->
              <div class="form-group">
                <label for="cylindree">Cylindrée</label>
                <input
                  type="text"
                  formControlName="cylindree"
                  class="form-control"
                  [ngClass]="{
                    'is-invalid':
                      vehiculeForm.get('cylindree')?.invalid &&
                      vehiculeForm.get('cylindree')?.touched
                  }"
                  placeholder="Cylindrée du véhicule"
                />
                <div
                  *ngIf="vehiculeForm.get('cylindree')?.errors?.['required'] && vehiculeForm.get('cylindree')?.touched"
                  class="invalid-feedback"
                >
                  La cylindrée est requise.
                </div>
              </div>

              <!-- Nombre d'essieux -->
              <div class="form-group">
                <label for="nombreEssieux">Nombre d'essieux</label>
                <input
                  type="number"
                  formControlName="nombreEssieux"
                  class="form-control"
                  [ngClass]="{
                    'is-invalid':
                      vehiculeForm.get('nombreEssieux')?.invalid &&
                      vehiculeForm.get('nombreEssieux')?.touched
                  }"
                  placeholder="Nombre d'essieux"
                />
                <div
                  *ngIf="vehiculeForm.get('nombreEssieux')?.errors?.['required'] && vehiculeForm.get('nombreEssieux')?.touched"
                  class="invalid-feedback"
                >
                  Le nombre d'essieux est requis.
                </div>
              </div>

              <!-- Date de mise en circulation -->
              <div class="form-group">
                <label for="dateMiseCirculation"
                  >Date de mise en circulation</label
                >
                <input
                  type="date"
                  formControlName="dateMiseCirculation"
                  class="form-control"
                  [ngClass]="{
                    'is-invalid':
                      vehiculeForm.get('dateMiseCirculation')?.invalid &&
                      vehiculeForm.get('dateMiseCirculation')?.touched
                  }"
                />
                <div
                  *ngIf="vehiculeForm.get('dateMiseCirculation')?.errors?.['required'] && vehiculeForm.get('dateMiseCirculation')?.touched"
                  class="invalid-feedback"
                >
                  La date de mise en circulation est requise.
                </div>
              </div>
            </div>

            <div class="col-md-6">
              <!-- Date de la carte grise -->
              <div class="form-group">
                <label for="dateCarteGrise">Date de la carte grise</label>
                <input
                  type="date"
                  formControlName="dateCarteGrise"
                  class="form-control"
                  [ngClass]="{
                    'is-invalid':
                      vehiculeForm.get('dateCarteGrise')?.invalid &&
                      vehiculeForm.get('dateCarteGrise')?.touched
                  }"
                />
                <div
                  *ngIf="vehiculeForm.get('dateCarteGrise')?.errors?.['required'] && vehiculeForm.get('dateCarteGrise')?.touched"
                  class="invalid-feedback"
                >
                  La date de la carte grise est requise.
                </div>
              </div>

              <!-- Puissance fiscale -->
              <div class="form-group">
                <label for="puissanceFiscale">Puissance fiscale</label>
                <input
                  type="text"
                  formControlName="puissanceFiscale"
                  class="form-control"
                  [ngClass]="{
                    'is-invalid':
                      vehiculeForm.get('puissanceFiscale')?.invalid &&
                      vehiculeForm.get('puissanceFiscale')?.touched
                  }"
                  placeholder="Puissance fiscale"
                />
                <div
                  *ngIf="vehiculeForm.get('puissanceFiscale')?.errors?.['required'] && vehiculeForm.get('puissanceFiscale')?.touched"
                  class="invalid-feedback"
                >
                  La puissance fiscale est requise.
                </div>
              </div>

              <!-- Mode -->
              <div class="form-group">
                <label for="mode">Mode</label>
                <select
                  formControlName="mode"
                  class="form-select"
                  [ngClass]="{
                    'is-invalid':
                      vehiculeForm.get('mode')?.invalid &&
                      vehiculeForm.get('mode')?.touched
                  }"
                >
                  <option value="">Sélectionner</option>
                  <option value="Automatique">Automatique</option>
                  <option value="Manuel">Manuel</option>
                </select>
                <div
                  *ngIf="vehiculeForm.get('mode')?.errors?.['required'] && vehiculeForm.get('mode')?.touched"
                  class="invalid-feedback"
                >
                  Le mode est requis.
                </div>
              </div>

              <!-- Usage -->
              <div class="form-group">
                <label for="usage">Usage</label>
                <select
                  formControlName="usage"
                  class="form-select"
                  [ngClass]="{
                    'is-invalid':
                      vehiculeForm.get('usage')?.invalid &&
                      vehiculeForm.get('usage')?.touched
                  }"
                >
                  <option value="">Sélectionner</option>
                  <option value="Publique">Publique</option>
                  <option value="Privé">Privé</option>
                </select>
                <div
                  *ngIf="vehiculeForm.get('usage')?.errors?.['required'] && vehiculeForm.get('usage')?.touched"
                  class="invalid-feedback"
                >
                  L'usage est requis.
                </div>
              </div>
            </div>
          </div>

          <div class="row" *ngIf="formStep === 3">
            <div class="col-md-6">
              <!-- Frais douane -->
              <div class="form-group">
                <label for="fraisDouane">Frais douane</label>
                <input
                  type="number"
                  formControlName="fraisDouane"
                  class="form-control"
                  [ngClass]="{
                    'is-invalid':
                      vehiculeForm.get('fraisDouane')?.invalid &&
                      vehiculeForm.get('fraisDouane')?.touched
                  }"
                  placeholder="Frais douane"
                />
                <div
                  *ngIf="vehiculeForm.get('fraisDouane')?.errors?.['required'] && vehiculeForm.get('fraisDouane')?.touched"
                  class="invalid-feedback"
                >
                  Les frais de douane sont requis.
                </div>
              </div>

              <!-- Montant TVA -->
              <div class="form-group">
                <label for="montantTva">Montant TVA</label>
                <input
                  type="number"
                  formControlName="montantTva"
                  class="form-control"
                  [ngClass]="{
                    'is-invalid':
                      vehiculeForm.get('montantTva')?.invalid &&
                      vehiculeForm.get('montantTva')?.touched
                  }"
                  placeholder="Montant TVA"
                />
                <div
                  *ngIf="vehiculeForm.get('montantTva')?.errors?.['required'] && vehiculeForm.get('montantTva')?.touched"
                  class="invalid-feedback"
                >
                  Le montant de la TVA est requis.
                </div>
              </div>

              <!-- Frais carte grise -->
              <div class="form-group">
                <label for="fraisCarteGrise">Frais carte grise</label>
                <input
                  type="number"
                  formControlName="fraisCarteGrise"
                  class="form-control"
                  [ngClass]="{
                    'is-invalid':
                      vehiculeForm.get('fraisCarteGrise')?.invalid &&
                      vehiculeForm.get('fraisCarteGrise')?.touched
                  }"
                  placeholder="Frais carte grise"
                />
                <div
                  *ngIf="vehiculeForm.get('fraisCarteGrise')?.errors?.['required'] && vehiculeForm.get('fraisCarteGrise')?.touched"
                  class="invalid-feedback"
                >
                  Les frais de carte grise sont requis.
                </div>
              </div>

              <!-- Autre frais -->
              <div class="form-group">
                <label for="autreFrais">Autre frais</label>
                <input
                  type="number"
                  formControlName="autreFrais"
                  class="form-control"
                  [ngClass]="{
                    'is-invalid':
                      vehiculeForm.get('autreFrais')?.invalid &&
                      vehiculeForm.get('autreFrais')?.touched
                  }"
                  placeholder="Autres frais"
                />
                <div
                  *ngIf="vehiculeForm.get('autreFrais')?.errors?.['required'] && vehiculeForm.get('autreFrais')?.touched"
                  class="invalid-feedback"
                >
                  Les autres frais sont requis.
                </div>
              </div>
            </div>

            <div class="col-md-6">
              <!-- Date d'achat -->
              <div class="form-group">
                <label for="dateAchat">Date d'achat</label>
                <input
                  type="date"
                  formControlName="dateAchat"
                  class="form-control"
                  [ngClass]="{
                    'is-invalid':
                      vehiculeForm.get('dateAchat')?.invalid &&
                      vehiculeForm.get('dateAchat')?.touched
                  }"
                />
                <div
                  *ngIf="vehiculeForm.get('dateAchat')?.errors?.['required'] && vehiculeForm.get('dateAchat')?.touched"
                  class="invalid-feedback"
                >
                  La date d'achat est requise.
                </div>
              </div>

              <!-- Prix d'achat -->
              <div class="form-group">
                <label for="prixAchat">Prix d'achat</label>
                <input
                  type="number"
                  formControlName="prixAchat"
                  class="form-control"
                  [ngClass]="{
                    'is-invalid':
                      vehiculeForm.get('prixAchat')?.invalid &&
                      vehiculeForm.get('prixAchat')?.touched
                  }"
                  placeholder="Prix d'achat"
                />
                <div
                  *ngIf="vehiculeForm.get('prixAchat')?.errors?.['required'] && vehiculeForm.get('prixAchat')?.touched"
                  class="invalid-feedback"
                >
                  Le prix d'achat est requis.
                </div>
              </div>

              <!-- Prix de vente -->
              <div class="form-group">
                <label for="prixVente">Prix de vente</label>
                <input
                  type="number"
                  formControlName="prixVente"
                  class="form-control"
                  [ngClass]="{
                    'is-invalid':
                      vehiculeForm.get('prixVente')?.invalid &&
                      vehiculeForm.get('prixVente')?.touched
                  }"
                  placeholder="Prix de vente"
                />
                <div
                  *ngIf="vehiculeForm.get('prixVente')?.errors?.['required'] && vehiculeForm.get('prixVente')?.touched"
                  class="invalid-feedback"
                >
                  Le prix de vente est requis.
                </div>
              </div>

              <!-- Prix de location -->
              <div class="form-group">
                <label for="prixLocation">Prix de location</label>
                <input
                  type="number"
                  formControlName="prixLocation"
                  class="form-control"
                  [ngClass]="{
                    'is-invalid':
                      vehiculeForm.get('prixLocation')?.invalid &&
                      vehiculeForm.get('prixLocation')?.touched
                  }"
                  placeholder="Prix de location"
                />
                <div
                  *ngIf="vehiculeForm.get('prixLocation')?.errors?.['required'] && vehiculeForm.get('prixLocation')?.touched"
                  class="invalid-feedback"
                >
                  Le prix de location est requis.
                </div>
              </div>
            </div>
          </div>

          <!-- Navigation entre les étapes -->
          <div class="d-flex justify-content-end">
            <button
              *ngIf="formStep > 0"
              type="button"
              (click)="previousStep()"
              class="btn btn-primary me-2"
            >
              Retour
            </button>
            <button
              *ngIf="formStep < 3"
              type="button"
              (click)="nextStep()"
              class="btn btn-primary"
            >
              Suivant
            </button>
            <button
              *ngIf="formStep == 3 && !isLoading"
              [disabled]="vehiculeForm.invalid"
              type="submit"
              class="btn btn-primary"
            >
              Enregistrer
            </button>
            <button
                *ngIf="isLoading"
                class="btn btn-primary"
                type="submit"
                disabled
              >
                <span class="spinner-grow spinner-grow-sm" role="status"></span>
                Traitement...
              </button>
          </div>
        </form>
      </app-card>
    </div>
  </div>
</div>
