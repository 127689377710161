<div class="row">
  <div class="col-sm-12">
    <app-card cardTitle="Fournisseurs">
      <div *ngIf="hasSuccess" class="alert alert-success mt-2 mb-2">
        <p>{{successMessage}}</p>
      </div>
      <div *ngIf="hasError" class="alert alert-danger mt-2 mb-2">
        <p>{{errorMessage}}</p>
      </div>

      <form [formGroup]="fournisseurForm" (ngSubmit)="onSubmit()">
        <div class="form-group">
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label for="nom">nom <span class="text-danger">*</span></label>
                <input type="text" formControlName="nom" class="form-control" id="nom" [ngClass]="{
                        'is-invalid':
                          fournisseurForm.get('nom')?.invalid &&
                          fournisseurForm.get('nom')?.touched
                      }" />
                <div *ngIf="
                        fournisseurForm.get('nom')?.invalid &&
                        fournisseurForm.get('nom')?.touched
                      " class="invalid-feedback">
                  <div *ngIf="fournisseurForm.get('nom')?.errors?.['required']">
                    Le nom est requis.
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label for="adresse">adresse</label>
                <input type="text" formControlName="adresse" class="form-control" id="adresse" [ngClass]="{
                    'is-invalid':
                      fournisseurForm.get('adresse')?.invalid &&
                      fournisseurForm.get('adresse')?.touched
                  }" />
                <div *ngIf="
                    fournisseurForm.get('adresse')?.invalid &&
                    fournisseurForm.get('adresse')?.touched
                  " class="invalid-feedback">
                  <div *ngIf="fournisseurForm.get('adresse')?.errors?.['required']">
                    La adresse est requise.
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label for="telephone">telephone<span class="text-danger">*</span></label>
                <input type="text" formControlName="telephone" class="form-control" id="telephone" [ngClass]="{
                    'is-invalid':
                      fournisseurForm.get('telephone')?.invalid &&
                      fournisseurForm.get('telephone')?.touched
                  }" />
                <div *ngIf="
                    fournisseurForm.get('telephone')?.invalid &&
                    fournisseurForm.get('telephone')?.touched
                  " class="invalid-feedback">
                  <div *ngIf="fournisseurForm.get('telephone')?.errors?.['required']">
                    La telephone est requise.
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label for="prenoms">prenoms<span class="text-danger">*</span></label>
                <input type="text" formControlName="prenoms" class="form-control" id="prenoms" [ngClass]="{
                    'is-invalid':
                      fournisseurForm.get('prenoms')?.invalid &&
                      fournisseurForm.get('prenoms')?.touched
                  }" />
                <div *ngIf="
                    fournisseurForm.get('prenoms')?.invalid &&
                    fournisseurForm.get('prenoms')?.touched
                  " class="invalid-feedback">
                  <div *ngIf="fournisseurForm.get('prenoms')?.errors?.['required']">
                    La prenoms est requise.
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label for="email">email<span class="text-danger">*</span></label>
                <input type="email" formControlName="email" class="form-control" id="email" [ngClass]="{
                    'is-invalid':
                      fournisseurForm.get('email')?.invalid &&
                      fournisseurForm.get('email')?.touched
                  }" />
                <div *ngIf="
                    fournisseurForm.get('email')?.invalid &&
                    fournisseurForm.get('email')?.touched
                  " class="invalid-feedback">
                  <div *ngIf="fournisseurForm.get('email')?.errors?.['required']">
                    La email est requise.
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label for="nom">Nom entreprise</label>
                <input type="text" formControlName="nomEntreprise" class="form-control" id="nom"  />
              </div>

            </div>
          </div>
        </div>
        <div class="d-flex justify-content-end">
          <button *ngIf="isLoading" class="btn btn-primary" type="submit" disabled>
            <span class="spinner-grow spinner-grow-sm" role="status"></span>
            Traitement...
          </button>

          <!-- Bouton "Enregistrer" -->
          <button type="submit" class="btn btn-primary" [disabled]="fournisseurForm.invalid" *ngIf="!isLoading">
            Enregistrer
          </button>
        </div>
      </form>
      <div class="mb-5"></div>
      <!-- table -->
        <app-table-fournisseur></app-table-fournisseur>
    </app-card>
  </div>
</div>
