import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GestionLocationVenteRoutingModule } from './gestion-location-vente-routing.module';


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    GestionLocationVenteRoutingModule
  ]
})
export class GestionLocationVenteModule { }
