import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TableFournisseurComponent } from "./table-fournisseur/table-fournisseur.component";
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, RouterModule } from '@angular/router';
import { FournisseurService } from './service/fournisseur.service';
import { FournisseurReponse } from './reponse/fournisseur.reponse';
import { FournisseurModele } from './modeles/fournisseur.modele';
import { SharedModule } from 'src/app/theme/shared/shared.module';

@Component({
  selector: 'app-fournisseur-liste',
  standalone: true,
  imports: [CommonModule,TableFournisseurComponent,SharedModule, RouterModule],
  templateUrl: './fournisseur.component.html',
  styleUrl: './fournisseur.component.scss',
})
export class FournisseurComponent {
  fournisseurForm: FormGroup;
  isLoading: boolean = false;
  dtRouterLinkOptions: object = {};
  hasError: boolean = false;
  hasSuccess: boolean = false;
  errorMessage: any;
  successMessage: any;


  constructor(
    private router: Router,
    private fb: FormBuilder,
    private fournisseurService: FournisseurService
  ) {
    this.fournisseurForm = this.fb.group({
      nom: ['', [Validators.required]],
      nomEntreprise: [''],
      prenoms: ['', [Validators.required]],
      email: [[''], [Validators.required]],
      adresse: [[''], [Validators.required]],
      telephone: [[''], [Validators.required]],
    });
  }

  // Méthode appelée à la soumission du formulaire
  onSubmit() {
    this.isLoading = true;
    this.hasError = false;
    this.hasSuccess = true;
    if (this.fournisseurForm.valid) {
      const payload: FournisseurModele = this.fournisseurForm.value;
      this.fournisseurService.create(payload).subscribe(
        (response: FournisseurReponse) => {
          this.isLoading = false;
          this.hasSuccess = true;
          this.successMessage = 'Fournisseur ajouté avec succès.';
          this.fournisseurForm.reset();
        },
        (error: any) => {
          this.hasError = true;
          this.isLoading = false;
          this.errorMessage = error.error.message;
          console.error('Erreur lors de la création du Fournisseur', error);
        }
      );
    } else {
      console.log('Le formulaire est invalide');
    }
  }

  // Méthode pour envoyer la requête


  ngOnInit(): void {

  }
}
