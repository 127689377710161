// Angular import
import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
// project import
import { SharedModule } from 'src/app/theme/shared/shared.module';
// third party

import { TableVenteComponent } from "../table-vente/table-vente.component";
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable, EMPTY } from 'rxjs';
import { DataService } from 'src/app/data/data.service';
import { VilleReponse } from 'src/app/data/ville.reponse';
import { Config } from 'src/app/helpers/config';
import { ChauffeurReponse } from '../../gestion-chauffeur/reponse/chauffeur.reponse';
import { ChauffeurService } from '../../gestion-chauffeur/service/chauffeur.service';
import { DetailCommande, VenteVehiculeModele } from '../../gestion-location-vente/vente-vehicule/modeles/vente.vehicule.modele';
import { VenteVehiculeReponse } from '../../gestion-location-vente/vente-vehicule/reponse/vente.vehicule.reponse';
import { VenteVehiculeService } from '../../gestion-location-vente/vente-vehicule/service/vente.vehicule.service';
import { ClientReponse } from '../../gestion-partenaire/client/reponse/client.reponse';
import { ClientService } from '../../gestion-partenaire/client/service/client.service';
import { VehiculeReponse } from '../../gestion-vehicule/vehicule/reponses/vehicule.reponse';
import { VehiculeService } from '../../gestion-vehicule/vehicule/services/vehicule.service';
import { NgSelectModule } from '@ng-select/ng-select';
import { ProduitReponse } from '../../gestion-article/reponse/produit.reponse';
import { CategorieReponse } from '../../gestion-categorie/reponse/categorie.reponse';
import { ProduitService } from '../../gestion-article/service/produit.service';
import { CategorieService } from '../../gestion-categorie/service/categorie.service';
import { StockService } from '../../gestion-stock/service/stock.service';
import { StockReponse } from '../../gestion-stock/reponse/stock.reponse';


interface TodoItem {
  cId: any;
  pId: any;
  catId: any | null;
  qte: any;
  msg: string;
  amount: string;
  remise: string;
  total: string;

}
@Component({
  selector: 'app-creation-vente',
  standalone: true,
  imports: [CommonModule, NgSelectModule, SharedModule],
  templateUrl: './creation-vente.component.html',
  styleUrl: './creation-vente.component.scss'
})
export class CreationVenteComponent implements OnInit {

  selectedOption: any;
  venteForm: FormGroup;
  isLoading: boolean = false;
  hasError: boolean = false;
  hasSuccess: boolean = false;
  errorMessage: any;
  successMessage: any;
  produitList$: Observable<StockReponse[]> = EMPTY;
  clientList$: Observable<ClientReponse[]> = EMPTY;
  categorieList$: Observable<CategorieReponse[]> = EMPTY;
  produitSelected: StockReponse = {} as StockReponse;
  clientSelected: ClientReponse = {} as ClientReponse;
  categorieSelected: CategorieReponse = {} as CategorieReponse;
  // private props
  todoList: TodoItem[] = [];
  total = 0;
  totalRemse = 0;
  totalChauffeur = 0;

  // Constructor
  constructor(private modalService: NgbModal, private fb: FormBuilder, private produitService: StockService,
    private clientService: ClientService, private venteVehiculeService: VenteVehiculeService,
    private categorieService: CategorieService, private dataService: DataService) {
    this.venteForm = this.fb.group({
      idClient: ['', [Validators.required]],
      idProduit: ['', [Validators.required]],
      quantite: ['', [Validators.required]],
      montant: ['', [Validators.required]],
      idCategorie: ['', [Validators.required]],
      tva: [0],
      remise: [0],
    });

    this.clientList$ = this.findClient();
    this.categorieList$ = this.categorieService.getAll();
    this.venteForm.get('idCategorie')?.valueChanges.subscribe((valeur) => {
      this.produitList$ = this.produitService.getAllStockEnCours(valeur);
    })

  }
  ngOnInit(): void {

    // Subscribe to changes in the 'tva' form control
    this.venteForm.get('tva')?.valueChanges.subscribe(() => {
      this.calculateTotalRemise();
    });

    // Optionally, subscribe to changes in the 'remise' form control if needed
    this.venteForm.get('remise')?.valueChanges.subscribe(() => {
      this.calculateTotalRemise();
    });

  }

  // Fonction pour sélectionner une valeur dans le ng-select
  selectProduit(stockReponse: StockReponse): void {
    //if (!this.isDisabled(stockReponse.immatriculationProvisoire)) {
    this.venteForm.get('idProduit')?.setValue(stockReponse.idProduit);
    this.venteForm.get('montant')?.setValue(stockReponse.prixUnitaireVente);
    this.produitSelected = stockReponse;
    //}

  }

  selectClient(clientReponse: ClientReponse): void {
    this.venteForm.get('idClient')?.setValue(clientReponse.idClient);
    this.clientSelected = clientReponse;
  }

  selectCategorie(categorieReponse: CategorieReponse): void {
    this.venteForm.get('idCategorie')?.setValue(categorieReponse.idCategorie);
    this.categorieSelected = categorieReponse;
  }
  formatNumberWithSpace(value: number): string {
    if (value == null) {
      return '0'; // or any other default value
    }
    return value + ''.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
  }
  // private method
  addTodoList() {
    this.todoList.push({
      cId: this.clientSelected.idClient,
      pId: this.produitSelected.idProduit,
      qte: this.venteForm.get('quantite')?.value,
      catId: this.categorieSelected.idCategorie,
      msg: this.produitSelected.produit?.libelle,
      amount: this.venteForm.get('montant')?.value,
      remise: this.venteForm.get('remise')?.value,
      total: this.venteForm.get('montant')?.value,
    });

    this.calculateTotal();
    const tvaValue = this.venteForm.get('tva')?.value;

    // Reset the form
    this.venteForm.reset();

    // Restore the TVA value
    this.venteForm.patchValue({ tva: tvaValue });
    this.venteForm.patchValue({ idClient: this.clientSelected.idClient });

  }
  calculateTotalRemise(): void {
    // Ensure the values from the form are numbers, defaulting to 0 if they are null or undefined
    const tvaValue = this.venteForm.get('tva')?.value || 0;
    // Calculate the total from the todoList, ensuring each item's total is a valid number
    // Calculate the total from the todoList, ensuring each item's total is a valid number
    const totalFromTodoList = this.todoList.reduce((sum, item) => {
      let itemTotal = Config.calculateTotalPrice((parseFloat(item.total) * parseFloat(item.qte) || 0), 1);
      const remiseValue = (parseFloat(item.remise!) || 0) / 100;
      itemTotal = itemTotal - (itemTotal * (remiseValue));
      return (sum + itemTotal)
    }, 0);
    if (tvaValue > 0) {
      this.totalRemse = totalFromTodoList + (totalFromTodoList * (tvaValue / 100)) + this.totalChauffeur;
    } else {
      this.totalRemse = totalFromTodoList + this.totalChauffeur;
    }
  }

  calculMontant(item: any) {
    return Config.calculateTotalPrice((parseFloat(item.total) * parseFloat(item.qte) || 0), 1);
  }

  /*************  ✨ Codeium Command ⭐  *************/
  /**
   * Calculates the total of the todoList.
   * The total is the sum of each item's montant.
   * After calculating the total, it calls calculateTotalRemise to update the totalRemse.
   */
  /******  e9a71750-a62d-45ae-a6a8-0b080e5df474  *******/
  calculateTotal(): void {
    this.total = this.todoList.reduce((sum, item) => sum + this.calculMontant(item), 0);
    this.calculateTotalRemise();
  }


  removeItem(vId: any): void {
    this.todoList = this.todoList.filter(item => item.pId !== vId);
    this.calculateTotal();
  }






  onSubmit() {
    this.isLoading = true;
    this.hasError = false;
    this.hasSuccess = false;
    if (this.todoList.length > 0) {
      const details = this.todoList.map((item) => new DetailCommande(
        null,
        item.amount,
        item.qte,
        null,
        null,
        null,
        null,
        0,
        item.remise,
        null,
        null,
        null,
        item.pId,
      ));

      const payload = new VenteVehiculeModele(
        this.todoList.reduce((sum, item) =>
          sum + Number(item.amount), 0
        ),
        "V_PRODUIT",
        0,
        this.todoList[0].cId,
        this.venteForm.get('tva')?.value || 0,
        details
      );

      this.venteVehiculeService.create(payload).subscribe(
        (response: VenteVehiculeReponse) => {
          this.isLoading = false;
          this.hasSuccess = true;
          this.hasError = false;
          this.successMessage = 'Vente créée avec succès.';
          this.todoList = [];
          this.venteForm.reset();
        },
        (error: any) => {
          this.hasError = true;
          this.hasSuccess = false;
          this.isLoading = false;
          this.errorMessage = error.error.message;
          console.error('Erreur lors de la création de la vente', error);
        }
      );
    } else {
      console.log('Le formulaire est invalide');
    }
  }



  findClient(): Observable<ClientReponse[]> {
    return this.clientService.getAll();
  }


}

