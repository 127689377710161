@if (!item.hidden) {
  @if (item.url && !item.external) {
    @if (currentLayout === 'vertical') {
      <li [ngClass]="item.classes!" [routerLinkActive]="['active']">
        <a class="nav-link" [target]="item.target ? '_blank' : '_self'" [routerLink]="[item.url]" (click)="closeOtherMenu($event)">
          <ng-container *ngTemplateOutlet="itemIcon"></ng-container>
          @if (item.icon) {
            <span class="coded-mtext">{{ item.title | translate }}</span>
          } @else {
            {{ item.title | translate }}
          }
          <ng-container *ngTemplateOutlet="itemBadge"></ng-container>
        </a>
      </li>
    } @else if (currentLayout === 'horizontal') {
      <li [ngClass]="item.classes!" [routerLinkActive]="['active']">
        <a class="nav-link" [target]="item.target ? '_blank' : '_self'" [routerLink]="[item.url]" (click)="closeOtherMenu($event)">
          <ng-container *ngTemplateOutlet="itemIcon"></ng-container>
          @if (item.icon) {
            <span class="coded-mtext">{{ item.title | translate }}</span>
          } @else {
            {{ item.title | translate }}
          }
          <ng-container *ngTemplateOutlet="itemBadge"></ng-container>
        </a>
      </li>
    } @else if (currentLayout === 'compact') {
      <li
        [ngClass]="item.classes!"
        [routerLinkActive]="['active']"
        [placement]="'top'"
        [ngbTooltip]="item.type === 'item' ? item.title : null"
      >
        <a
          class="nav-link"
          [target]="item.target ? '_blank' : '_self'"
          [routerLink]="[item.url]"
          (click)="closeOtherMenu($event); subMenuCollapse()"
        >
          <ng-container *ngTemplateOutlet="itemIcon"></ng-container>
          @if (item.icon) {
            <span class="coded-mtext">{{ item.title | translate }}</span>
          } @else {
            {{ item.title | translate }}
          }
          <ng-container *ngTemplateOutlet="itemBadge"></ng-container>
        </a>
      </li>
    }
  }
  @if (item.url && item.external) {
    <li [ngClass]="item.classes!">
      <a [target]="item.target ? '_blank' : '_self'" [href]="item.url">
        <ng-container *ngTemplateOutlet="itemIcon"></ng-container>
        @if (item.icon) {
          <span class="coded-mtext">{{ item.title | translate }}</span>
        } @else {
          {{ item.title | translate }}
        }
        <ng-container *ngTemplateOutlet="itemBadge"></ng-container>
      </a>
    </li>
  }
  <ng-template #itemIcon>
    @if (item.icon) {
      <span class="coded-micon"><i class="feather" [ngClass]="item.icon"></i></span>
    }
  </ng-template>
  <ng-template #itemBadge>
    @if (item.badge && themeLayout === 'vertical') {
      <span class="coded-badge label" [ngClass]="item.badge.type!">
        {{ item.badge.title }}
      </span>
    } @else if (item.badge && themeLayout === 'horizontal') {
      <span class="badge label" [ngClass]="item.badge.type!">
        {{ item.badge.title }}
      </span>
    } @else if (item.badge && themeLayout === 'compact') {
      <span class="coded-badge label" [ngClass]="item.badge.type!">
        {{ item.badge.title }}
      </span>
    }
  </ng-template>
}
