<div class="row">
  <!-- [ sample-page ] start -->
  <div class="col-sm-12">
    <div class="card">
      <div class="card-header">
        <h5>Créer une facture proforma</h5>
      </div>
      <div class="card-body">
        <hr class="my-3" />
        <div *ngIf="hasSuccess" class="alert alert-success mt-2 mb-2">
          <p>{{successMessage}}</p>
        </div>
        <div *ngIf="hasError" class="alert alert-danger mt-2 mb-2">
          <p>{{errorMessage}}</p>
        </div>
        <form [formGroup]="venteForm" (ngSubmit)="onSubmit()">
          <div class="row d-flex justify-content-end">
            <div class="col-sm-6">
              <div class="form-group">
                <label class="form-label">Client <span class="text-danger">*</span></label>
                <ng-select [selectOnTab]="true" #clientSelect [items]="clientList$ | async" [selectOnTab]="true"
                  (change)="selectClient($event)" bindValue="idClient" labelForId="idClient" formControlName="idClient"
                  [ngClass]="{
                  'is-invalid':
                    venteForm.get('idClient')?.invalid &&
                    venteForm.get('idClient')?.touched
                }">
                  <!-- Template pour le label affiché dans le champ sélectionné -->
                  <ng-template ng-label-tmp let-item="item">
                    {{ item.nom }} {{ item.prenoms }} {{ item.telephone }}
                  </ng-template>

                  <!-- Template pour les options dans la liste déroulante -->
                  <ng-template ng-option-tmp let-item="item">
                    {{ item.nom }} {{ item.prenoms }} {{ item.telephone }}
                  </ng-template>

                </ng-select>
                <div *ngIf="
              venteForm.get('idClient')?.invalid &&
              venteForm.get('idClient')?.touched
            " class="invalid-feedback">
                  <div *ngIf="clientForm.get('idClient')?.errors?.['required']">
                    Le client est requis.
                  </div>
                </div>
              </div>
            </div>
            <div class="col-sm-6">
              <div class="form-group">
                <label class="form-label">Véhicule <span class="text-danger">*</span></label>

                <ng-select #vehiculeSelect [items]="activatedVehicules" [selectOnTab]="true"
                  (change)="selectVehicule($event)" bindValue="immatriculationProvisoire" labelForId="idVehicule"
                  formControlName="idVehicule" [ngClass]="{
                      'is-invalid':
                        venteForm.get('idVehicule')?.invalid &&
                        venteForm.get('idVehicule')?.touched
                    }">

                  <ng-template ng-label-tmp let-item="item">
                    {{ item.immatriculationProvisoire }} -
                    {{ item.marque?.libelle }} -
                    {{ item.modele?.libelle }}
                  </ng-template>

                  <!-- Template for dropdown options -->
                  <ng-template ng-option-tmp let-item="item">
                    <div [ngClass]="{ 'ng-option': true, 'disabled': isDisabled(item.immatriculationProvisoire) }">
                      {{ item.immatriculationProvisoire }} -
                      {{ item.marque?.libelle }} -
                      {{ item.modele?.libelle }}
                    </div>
                  </ng-template>
                </ng-select>

                <div *ngIf="
              venteForm.get('idVehicule')?.invalid &&
              venteForm.get('idVehicule')?.touched
            " class="invalid-feedback">
                  <div *ngIf="clientForm.get('idVehicule')?.errors?.['required']">
                    Le véhicule est requis.
                  </div>
                </div>
              </div>
            </div>
            <div class="col-sm-6">
              <div class="form-group">
                <label for="villeDepart">Ville de depart</label>
                <ng-select [selectOnTab]="true" #villeDepartSelect [items]="villeList$ | async" [selectOnTab]="true"
                  (change)="selectVilleDepart($event)" bindValue="city" labelForId="idCity"
                  formControlName="villeDepart" [ngClass]="{
                                    'is-invalid':
                                      venteForm.get('villeDepart')?.invalid &&
                                      venteForm.get('villeDepart')?.touched
                                  }">
                  <!-- Template pour le label affiché dans le champ sélectionné -->
                  <ng-template ng-label-tmp let-item="item">
                    {{ item.city }}
                  </ng-template>

                  <!-- Template pour les options dans la liste déroulante -->
                  <ng-template ng-option-tmp let-item="item">
                    {{ item.city }}
                  </ng-template>

                </ng-select>
                <input type="text" formControlName="villeDepart" class="form-control mt-1" [ngClass]="{
                    'is-invalid':
                      venteForm.get('villeDepart')?.invalid &&
                      venteForm.get('villeDepart')?.touched
                  }" />
                <div *ngIf="venteForm.get('villeDepart')?.errors?.['required'] && venteForm.get('villeDepart')?.touched"
                  class="invalid-feedback">
                  La ville de depart est requise.
                </div>
              </div>
            </div>
            <div class="col-sm-6">
              <div class="form-group">
                <label for="villeArrivee">Ville d'arrivée</label>
                <ng-select [selectOnTab]="true" #villeArriveeSelect [items]="villeList$ | async" [selectOnTab]="true"
                  (change)="selectVilleArrivee($event)" bindValue="city" labelForId="idCity"
                  formControlName="villeArrivee" [ngClass]="{
                                                    'is-invalid':
                                                      venteForm.get('villeArrivee')?.invalid &&
                                                      venteForm.get('villeArrivee')?.touched
                                                  }">
                  <!-- Template pour le label affiché dans le champ sélectionné -->
                  <ng-template ng-label-tmp let-item="item">
                    {{ item.city }}
                  </ng-template>

                  <!-- Template pour les options dans la liste déroulante -->
                  <ng-template ng-option-tmp let-item="item">
                    {{ item.city }}
                  </ng-template>

                </ng-select>
                <input type="text" formControlName="villeArrivee" class="form-control mt-1" [ngClass]="{
                    'is-invalid':
                      venteForm.get('villeArrivee')?.invalid &&
                      venteForm.get('villeArrivee')?.touched
                  }" />
                <div
                  *ngIf="venteForm.get('villeArrivee')?.errors?.['required'] && venteForm.get('villeArrivee')?.touched"
                  class="invalid-feedback">
                  La ville d'arrivée est requise.
                </div>
              </div>
            </div>
            <div class="col-sm-6">
              <div class="form-group">
                <label for="dateDebut">Date début location</label>
                <input type="date" formControlName="dateDebut" class="form-control" [ngClass]="{
                    'is-invalid':
                      venteForm.get('dateDebut')?.invalid &&
                      venteForm.get('dateDebut')?.touched
                  }" />
                <div *ngIf="venteForm.get('dateDebut')?.errors?.['required'] && venteForm.get('dateDebut')?.touched"
                  class="invalid-feedback">
                  La date début est requise.
                </div>
              </div>
            </div>
            <div class="col-sm-6">
              <div class="form-group">
                <label for="dateFin">Date fin location</label>
                <input type="date" formControlName="dateFin" class="form-control" [ngClass]="{
                    'is-invalid':
                      venteForm.get('dateFin')?.invalid &&
                      venteForm.get('dateFin')?.touched
                  }" />
                <div *ngIf="venteForm.get('dateFin')?.errors?.['required'] && venteForm.get('dateFin')?.touched"
                  class="invalid-feedback">
                  La date fin est requise.
                </div>
              </div>
            </div>
            <div class="col-sm-6">
              <div class="form-group">
                <label for="montant">Montant location<span class="text-danger">*</span></label>
                <input type="number" formControlName="montant" class="form-control" [ngClass]="{
                    'is-invalid':
                      venteForm.get('montant')?.invalid &&
                      venteForm.get('montant')?.touched
                  }" />
                <div *ngIf="
                    venteForm.get('montant')?.invalid &&
                    venteForm.get('montant')?.touched
                  " class="invalid-feedback">
                  <div *ngIf="clientForm.get('montant')?.errors?.['required']">
                    Le montant location est requis.
                  </div>
                </div>
              </div>
            </div>
            <div class="col-sm-6">
              <div class="form-group">
                <label class="form-label">Remise %</label>
                <input type="number" maxlength="3" class="form-control" formControlName="remise" />
              </div>
            </div>
            <hr class="my-3" />
            <div class="col-sm-12">
              <div class="form-group">
                <label for="driver">Avec ou sans chauffeur<span class="text-danger">*</span></label>
                <select formControlName="driver" class="form-select" [ngClass]="{
                                    'is-invalid':
                                      venteForm.get('driver')?.invalid &&
                                      venteForm.get('driver')?.touched
                                  }">
                  <option value="">Sélectionner</option>
                  <option value="Avec chauffeur">Avec chauffeur</option>
                  <option value="Sans chauffeur">Sans chauffeur</option>

                </select>
                <div
                  *ngIf="venteForm.get('typeCarburant')?.errors?.['required'] && venteForm.get('typeCarburant')?.touched"
                  class="invalid-feedback">
                  Le type de carburant est requis.
                </div>
              </div>


            </div>

            <hr class="my-3" />
            <div class="d-flex justify-content-end">
              <button type="button" class="btn btn-link-primary" [disabled]="venteForm.invalid" (click)="addTodoList()">
                + Ajouter
              </button>
            </div>
            <div class="my-2"></div>
            <div class="col-sm-2">
              <div class="form-group">
                <label class="form-label">Tva %</label>
                <input type="number" maxlength="3" class="form-control" formControlName="tva" />
              </div>
            </div>

          </div>
          <hr class="my-3" />

          <div class="my-2"></div>
          <div class="table-responsive">
            <table class="table">
              <thead>
                <tr>
                  <th>Description</th>

                  <th>Dates</th>
                  <th>Villes</th>
                  <th class="text-end">période</th>
                  <th class="text-end">Perdieme</th>
                  <th class="text-end">Remise</th>
                  <th class="text-end">P.U</th>
                  <th class="text-end">Total</th>
                  <th class="text-end"></th>
                </tr>
              </thead>
              <tbody>
                @for (task of todoList; track task) {
                <tr>
                  <td>
                    <div>
                      <h5 class="mb-1">{{ task.msg }}</h5>
                      <p class="mb-0 text-muted">{{ task.dis }}</p>
                    </div>
                  </td>
                  <td>{{task.dateDebut + ' - ' + task.dateFin }}</td>
                  <td>{{task.villeDepart + ' - ' + task.villeArrivee }}</td>
                  <td class="text-end">{{nombreJour(task)}}</td>
                  <td class="text-end">{{ formatNumberWithSpace(task.montantChauffeur) }}</td>
                  <td class="text-end">{{ formatNumberWithSpace(task.remise)}}%</td>
                  <td class="text-end">{{ formatNumberWithSpace(task.total) }}</td>
                  <td class="text-end">{{
                    formatNumberWithSpace((calculMontant(task)-(calculMontant(task)*(task.remise/100))+task.montantChauffeur))}}
                  </td>
                  <td class="text-end">
                    <div class="">
                      <a href="javascript:" title="Delete" class="delete_todolist text-danger"
                        (click)="removeItem(task.vId)"><i class="far fa-trash-alt"></i></a>
                    </div>
                  </td>
                </tr>
                }
              </tbody>
            </table>
          </div>
          <hr class="my-3" />
          <div class="rounded p-3 bg-light-primary">
            <div class="row justify-content-end">
              <div class="col-auto">
                <div class="table-responsive">
                  <table class="table table-borderless text-end mb-0">
                    <tbody>

                      <tr>
                        <th>Montant : </th>
                        <td> {{ formatNumberWithSpace(total)}}</td>
                      </tr>
                      <tr>
                        <th>Perdieme : </th>
                        <td> {{ formatNumberWithSpace(totalChauffeur)}}</td>
                      </tr>

                      <tr>
                        <th>Tva ({{venteForm.get('tva')?.value}} %): </th>
                        <td> {{ formatNumberWithSpace(venteForm.get('tva')?.value/100 * total) }}</td>
                      </tr>

                      <tr>
                        <td class="pe-0 pt-0">
                          <hr class="mb-3 mt-0" />
                          <h5 class="text-primary m-r-10">Total :</h5>
                        </td>
                        <td class="ps-0 pt-0">
                          <hr class="mb-3 mt-0" />
                          <h5 class="text-primary">
                            {{
                            formatNumberWithSpace(
                            totalRemse
                            )
                            }}
                          </h5>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div class="d-flex justify-content-end my-3">
            <button *ngIf="isLoading" class="btn btn-primary" type="submit" disabled>
              <span class="spinner-grow spinner-grow-sm" role="status"></span>
              Traitement...
            </button>

            <!-- Bouton "Enregistrer" -->
            <button type="submit" class="btn btn-primary" [disabled]="todoList.length === 0" *ngIf="!isLoading">
              Enregistrer
            </button>
          </div>
        </form>
      </div>

    </div>
  </div>
  <!-- [ sample-page ] end -->
</div>