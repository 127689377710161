import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ListeAssComponent } from './liste-ass/liste-ass.component';
import { AjoutAssComponent } from './ajout-ass/ajout-ass.component';
import { DetailAssComponent } from './detail-ass/detail-ass.component';

const routes: Routes = [
  {
    path: 'Liste-des-assures',
    component: ListeAssComponent
  },
  {
    path: 'Ajout_assure',
    component: AjoutAssComponent
  },
  {
    path: 'Detail-Assurée',
    component: DetailAssComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class GestionAssuranceRoutingModule { }
