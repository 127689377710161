import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { GestionVisiteRoutingModule } from './gestion-visite-routing.module';


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    GestionVisiteRoutingModule
  ]
})
export class GestionVisiteModule { }
