<div class="row">
  <div class="col-sm-12">
    <app-card cardTitle="Categories">
      <div *ngIf="hasSuccess" class="alert alert-success mt-2 mb-2">
        <p>{{successMessage}}</p>
      </div>
      <div *ngIf="hasError" class="alert alert-danger mt-2 mb-2">
        <p>{{errorMessage}}</p>
      </div>

      <form [formGroup]="categorieForm" (ngSubmit)="onSubmit()">
        <div class="form-group">
          <div class="row">
            <div class="col-md-6">

            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label for="libelle">Libellé <span class="text-danger">*</span></label>
                <input type="text" formControlName="libelle" class="form-control" id="libelle" [ngClass]="{
                        'is-invalid':
                          categorieForm.get('libelle')?.invalid &&
                          categorieForm.get('libelle')?.touched
                      }" />
                <div *ngIf="
                        categorieForm.get('libelle')?.invalid &&
                        categorieForm.get('libelle')?.touched
                      " class="invalid-feedback">
                  <div *ngIf="categorieForm.get('libelle')?.errors?.['required']">
                    Le libellé est requis.
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label for="code">Code <span class="text-danger">*</span></label>
                <input type="text" formControlName="code" class="form-control" id="code" [ngClass]="{
                                      'is-invalid':
                                        categorieForm.get('code')?.invalid &&
                                        categorieForm.get('code')?.touched
                                    }" />
                <div *ngIf="
                                      categorieForm.get('code')?.invalid &&
                                      categorieForm.get('code')?.touched
                                    " class="invalid-feedback">
                  <div *ngIf="categorieForm.get('code')?.errors?.['required']">
                    Le code est requis.
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label for="description">Description</label>
                <textarea type="text" formControlName="description" class="form-control" id="description"></textarea>
              </div>
            </div>

          </div>
        </div>
        <div class="d-flex justify-content-end">
          <button *ngIf="isLoading" class="btn btn-primary" type="submit" disabled>
            <span class="spinner-grow spinner-grow-sm" role="status"></span>
            Traitement...
          </button>

          <!-- Bouton "Enregistrer" -->
          <button type="submit" class="btn btn-primary" [disabled]="categorieForm.invalid" *ngIf="!isLoading">
            Enregistrer
          </button>
        </div>
      </form>
      <div class="mb-5"></div>
      <!-- table -->
      <app-table-categorie></app-table-categorie>
    </app-card>
  </div>
</div>