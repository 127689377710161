import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Login } from '../models/login';

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  private url: string = `${environment.BaseUrl}`;
  private key: string = `${environment.key}`;

  constructor(private http: HttpClient) {}

  // Définition des options HTTP avec en-têtes
  private getHttpOptions(): { headers: HttpHeaders } {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept-Language': 'fr-FR',
        //'X-KEY': this.key
      })
    };
  }

  // Fonction de connexion
  login(payload: Login): Observable<any> {
    return this.http.post<any>(`${this.url}/auth/admin`, payload, this.getHttpOptions());
  }

  // Fonction de réinitialisation de mot de passe
  reset(payload: any): Observable<any> {
    return this.http.post<any>(`${this.url}/users/password/reset`, payload, this.getHttpOptions());
  }
}
