<div class="row">
    <!-- [ sample-page ] start -->
    <div class="col-sm-12">
      <div class="card">
        <div class="card-header pb-0">
          <ul
            ngbNav
            aria-busy="true"
            #nav="ngbNav"
            class="nav-tabs nav profile-tabs">
            <li class="nav-item" [ngbNavItem]="2">
              <a href="javascript:" ngbNavLink class="nav-link">
                <i class="material-icons-two-tone me-2">description</i>Enregistrement d'une Fiche de Maintenance
              </a>
              <ng-template ngbNavContent>
                <div class="row">
                  <div class="col-md-6">
                    <form [formGroup]="" >
                    <app-card cardTitle="Nouveau Rechargement">
                      <div class="row">
                        <div class="col-sm-6">
                          <div class="form-group">
                            <label class="form-label" for="First Name">Référence au véhicule <span class="text-danger">*</span></label>
                            <input type="text" id="vehicule" class="form-control" value="Ashoka Tano" required/>
                          </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="form-group">
                              <label class="form-label"
                                >Type de service<span class="text-danger"
                                  >*</span
                                ></label
                              >
                              <select class="form-control" title="select">
                                <option>Startup</option>
                                <option>Révision</option>
                                <option>Réparation</option>
                              </select>
                            </div>
                          </div>
                        <div class="col-sm-6">
                          <div class="form-group">
                            <label class="form-label" for="Second Name">Date du service <span class="text-danger">*</span></label>
                            <input type="date" id="date" class="form-control" value="Ashoka Tano" required/>
                          </div>
                        </div>                          
                        <div class="col-sm-6">
                            <div class="form-group">
                              <label class="form-label" for="Second Name">Date prévue pour le prochain service<span class="text-danger">*</span></label>
                              <input type="date" id="date" class="form-control" value="Ashoka Tano" required/>
                            </div>
                          </div>
                        <div class="col-sm-6">
                            <div class="form-group">
                              <label class="form-label" for="Second Name">Coût du service<span class="text-danger">*</span></label>
                              <input type="text" id="Coût" class="form-control" value="Ashoka Tano" required/>
                            </div>
                          </div>

                        <div class="col-sm-12">
                          <div class="form-group">
                            <label class="form-label"
                              >Description des travaux effectués <span class="text-danger">*</span></label>
                            <textarea class="form-control" placeholder="Enter Your Message" > I consider myself as a creative, professional and a flexible person. I can adapt with any kind of brief and design style</textarea>
                          </div>
                        </div>
                      </div>
                    </app-card>
                    <div class="card-footer text-end">
                        <button class="btn btn-primary">Enregistrer</button>
                        <button class="btn btn-outline-secondary ms-2">Effacer</button>
                      </div>
                    </form>
                  </div>
                </div>
              </ng-template>
            </li>
          </ul>
        </div>
        <div class="card-body">
          <div [ngbNavOutlet]="nav" class="mt-2"></div>
        </div>
      </div>
    </div>
    <!-- [ sample-page ] end -->
  </div>
  