import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { DocumentReponse, ParametreReponse, VehiculeReponse } from '../reponses/vehicule.reponse';
import { DocumentModele, VehiculeModele } from '../modeles/vehicule.modele';
import { SessionService } from 'src/app/Pages/auth/services/session.service';

@Injectable({
  providedIn: 'root'
})
export class VehiculeService {
  private url: string = `${environment.BaseUrl}`;
  private key: string = `${environment.key}`;
  constructor(private http: HttpClient, private localStorageService: SessionService) { }
  private token: string = 'Bearer ' + this.localStorageService.getData('token');
  // Définition des options HTTP avec en-têtes
  private getHttpOptions(): { headers: HttpHeaders } {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept-Language': 'fr-FR',
        'Authorization': this.token,
        //'X-KEY': this.key,
      }),
    };
  }

  create(payload: VehiculeModele): Observable<VehiculeReponse> {
    return this.http.post<any>(`${this.url}/vehicules`, payload, this.getHttpOptions());
  }
  createDocument(payload: DocumentModele): Observable<DocumentReponse> {
    return this.http.post<any>(`${this.url}/documents`, payload, this.getHttpOptions());
  }

  update(payload: VehiculeModele, id: any): Observable<VehiculeReponse> {
    return this.http.put<any>(`${this.url}/vehicules/${id}`, payload, this.getHttpOptions());
  }
  updateForModification(id: any): Observable<VehiculeReponse> {
    return this.http.put<any>(`${this.url}/vehicules/workflow/${id}/to/modifier`, null, this.getHttpOptions());
  }

  updateState(id: any): Observable<VehiculeReponse> {
    return this.http.put<any>(`${this.url}/vehicules/workflow/${id}`, null, this.getHttpOptions());
  }


  delete(id: any): Observable<VehiculeReponse> {
    return this.http.delete<any>(`${this.url}/vehicules/${id}`, this.getHttpOptions());
  }

  getById(id: any): Observable<VehiculeReponse> {
    return this.http.get<any>(`${this.url}/vehicules/${id}`, this.getHttpOptions());
  }
  getByIdForModification(id: any): Observable<VehiculeReponse> {
    return this.http.get<any>(`${this.url}/vehicules/modifier/${id}`, this.getHttpOptions());
  }


  getAll(query?: any): Observable<VehiculeReponse[]> {
    return this.http.get<any>(`${this.url}/vehicules?${query}`, this.getHttpOptions());
  }

  getParametresType(type: any): Observable<ParametreReponse[]> {
    return this.http.get<any>(`${this.url}/parametres/type/${type}`, this.getHttpOptions());
  }


  getDocumentByIdParentAndParent(parent: any, idParent: any): Observable<DocumentReponse[]> {
    return this.http.get<any>(`${this.url}/documents/parent/${parent}/idParent/${idParent}`, this.getHttpOptions());
  }
  deleteDocumentById(id: any): Observable<DocumentReponse> {
    return this.http.delete<any>(`${this.url}/documents/${id}`, this.getHttpOptions());
  }


}
