import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ListeMaintenanceComponent } from './liste-maintenance/liste-maintenance.component';
import { CreationMaintenanceComponent } from './creation-maintenance/creation-maintenance.component';
import { MaintenanceDetailComponent } from './maintenance-detail/maintenance-detail.component';

const routes: Routes = [
  {
    path: 'liste-maintenances',
    component: ListeMaintenanceComponent
  },
  {
    path: 'ajout-maintenances',
    component: CreationMaintenanceComponent
  },
  {
    path: 'detail-maintenances/:id',
    component: MaintenanceDetailComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class GestionMaintenanceRoutingModule { }
