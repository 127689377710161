<div class="row">
    <div class="col-sm-12">
      <div class="card">
        <div class="card-body">
          <ul
            class="nav nav-tabs profile-tabs border-bottom mb-3 d-print-none"
            aria-busy="true"
            ngbNav
            #nav="ngbNav"
            id="myTab"
            role="tablist"
          >
            <li [ngbNavItem]="2">
              <a ngbNavLink> <i class="material-icons-two-tone me-2">receipt</i>Invoice</a>
              <ng-template ngbNavContent>
                <div class="card border invoice-card">
                  <div class="card-header">
                    <div class="row align-items-center">
                      <div class="col-sm-6">
                        <h5 class="mb-0">Invoice #125863478945</h5>
                      </div>
                      <div class="col-sm-6 text-sm-end">
                        <img src="assets/images/logo-dark.svg" alt="logo" class="img-fluid" />
                      </div>
                    </div>
                  </div>
                  <div class="card-body">
                    <div class="row mb-3">
                      <div class="col-md-4">
                        <h4 class="mb-2"><b>Demo Company Inc.</b></h4>
                        <p class="text-muted mb-1"> 1065 Mandan Road, Columbia MO, </p>
                        <p class="text-muted mb-1">Missouri. (123)-65202</p>
                        <p class="text-muted mb-1">(+61) 9999 567 891</p>
                        <p class="text-muted mb-1">
                          <a href="mailto:demo@company.com">demo&#64;company.com</a>
                        </p>
                        <p class="text-muted">(+91) 9999 999 999</p>
                      </div>
                    </div>
                    <div class="row mb-3">
                      <div class="col-md-6">
                        <h4 class="mb-2"><b>Customer : John Doe</b></h4>
                        <p class="text-muted mb-1"> 1065 Mandan Road, Columbia MO, </p>
                        <p class="text-muted mb-1">Missouri. (123)-65202</p>
                        <p class="text-muted mb-1">(+61) 9999 567 891</p>
                        <p class="text-muted mb-1">
                          <a href="mailto:demo@company.com">demo&#64;company.com</a>
                        </p>
                      </div>
                      <div class="col-md-6">
                        <h4 class="mb-2"><b>Order Details :</b></h4>
                        <p class="text-muted mb-1">Date : November 14</p>
                        <p class="text-muted mb-1">
                          status:
                          <span class="badge text-warning border border-warning rounded-pill f-12 align-baseline">Paid</span>
                        </p>
                        <p class="text-muted">Order Id : #146859</p>
                      </div>
                    </div>
                    <app-details [trash]="false"></app-details>
                    <div class="mb-3">
                      <ng-container *ngTemplateOutlet="totalAmount"></ng-container>
                    </div>
                    <h5><b>Terms and Condition :</b></h5>
                    <p class="text-muted">
                      lorem ipsum dolor sit connecter adieu siccing eliot, sed do elusion tempore incident ut laborer et dolors magna aliquot.
                    </p>
                  </div>
                </div>
                <div class="text-center d-print-none mt-5 mb-3">
                  <button type="button" class="btn btn-print-invoice btn-primary m-b-10 m-r-10"> Print </button>
                  <button type="button" class="btn btn-secondary m-b-10"> Cancel </button>
                </div>
              </ng-template>
            </li>
          </ul>
          <div class="tab-content">
            <div [ngbNavOutlet]="nav" class="mt-2"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
  
  <ng-template #totalAmount>
    <div class="rounded p-3 bg-light-primary">
      <div class="row justify-content-end">
        <div class="col-auto">
          <div class="table-responsive">
            <table class="table table-borderless text-end mb-0">
              <tbody>
                @for (table of tables; track table) {
                  <tr>
                    <th>{{ table.title }}</th>
                    <td>{{ table.amount }}</td>
                  </tr>
                }
                <tr>
                  <td class="pe-0 pt-0">
                    <hr class="mb-3 mt-0" />
                    <h5 class="text-primary m-r-10">Total :</h5>
                  </td>
                  <td class="ps-0 pt-0">
                    <hr class="mb-3 mt-0" />
                    <h5 class="text-primary">$ 4827.00</h5>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
  