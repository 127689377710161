// angular import
import { Component, OnInit, TemplateRef } from '@angular/core';
import { CommonModule } from '@angular/common';

// bootstrap import
import { SharedModule } from 'src/app/theme/shared/shared.module';

// third party
import { Router, RouterModule } from '@angular/router';
import { DataTablesModule } from 'angular-datatables';
import { MarqueService } from '../services/marque.service';
import { SessionService } from 'src/app/Pages/auth/services/session.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-liste-m',
  standalone: true,
  imports: [CommonModule,DataTablesModule, SharedModule, RouterModule],
  templateUrl: './liste-m.component.html',
  styleUrl: './liste-m.component.scss'
})
export class ListeMComponent implements OnInit{

  // public props
  dtRouterLinkOptions: object = {};

  constructor(
    private marqueService: MarqueService,
    private localStorageService: SessionService,
   private router: Router,

 ){

 }

 ngOnInit() {
  this.initializeDataTable();
 }

 initializeDataTable() {
    const url: string = `${environment.BaseUrl}`;
    const token: string = 'Bearer '+this.localStorageService.getData('token');
    const lien = `${url}/marques`;
    this.dtRouterLinkOptions = {

      ajax: {
        url: lien,
        headers: {
          'Authorization':token
        },
        // URL to fetch data from
        dataSrc: '', // If the JSON response is an array, leave as an empty string
      },
      columns: [
        { title: 'Libellé', data: 'libelle' },
            { title: 'Description', data: 'description' },
            {
              title: 'Date Création',
              data: 'dateCreation',
              render: function (data: string) {
                const date = new Date(data);
                return date.toLocaleDateString('fr-FR', {
                  year: 'numeric',
                  month: 'long',
                  day: 'numeric'
                });
              }
            },
        {
          title: 'Action',
          data: null, // Ensures that DataTables doesn't look for this column in the dataset
          defaultContent: '',
          width: '100px',
          className: 'text-center',
          render: function () {
            return '<span><button class="btn btn-link-primary btn-view" title="View"><i class="ti ti-eye"></i></button></span> <span><button class="btn btn-link-primary" title="Delete"><i class="ti ti-trash"></i></button></span> <button class="btn btn btn-link-secondary" title="Edit"><i class="ti ti-pencil"></i></button>';
          }
        }
      ],
      responsive: true,
      select: true,
      drawCallback: () => {
        const component = this; // Capture the Angular component context
        // Handle the click event on the 'View' button
        $('.btn-view').on('click', function () {
          const rowElement = $(this).closest('tr');
          const rowData = $('#yourDataTable').DataTable().row(rowElement).data(); // Get the row data
          // Call the Angular route function
         // component.navigateToRoute(rowData); // Ensure correct context
        });
      }

    };


}


}
