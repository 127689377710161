import { Component, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';


import { SharedModule } from 'src/app/theme/shared/shared.module';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MaintenanceDetailComponent } from "../maintenance-detail/maintenance-detail.component";
import { environment } from 'src/environments/environment';
import { DataTableDirective, DataTablesModule } from 'angular-datatables';
import { SessionService } from '../../auth/services/session.service';



@Component({
  selector: 'app-liste-maintenance',
  standalone: true,
  imports: [CommonModule, DataTablesModule, SharedModule, MaintenanceDetailComponent, RouterModule],
  templateUrl: './liste-maintenance.component.html',
  styleUrl: './liste-maintenance.component.scss'
})
export class ListeMaintenanceComponent {
  baseUrl: any = environment.BaseUrl + '/documents/view/';
  // public props
  dtRouterLinkOptions: object = {};
  @ViewChild(DataTableDirective)
  datatableElement!: DataTableDirective;

  // Constructor
  constructor(private modalService: NgbModal, private localStorageService: SessionService) {
    this.initializeDataTable();
  }

  // private Method

  initializeDataTable() {
    const url: string = `${environment.BaseUrl}`;
    const token: string = 'Bearer ' + this.localStorageService.getData('token');
    const lien = `${url}/maintenances`;
    this.dtRouterLinkOptions = {

      ajax: {
        url: lien,
        headers: {
          'Authorization': token
        },
        // URL to fetch data from
        dataSrc: '', // If the JSON response is an array, leave as an empty string
      },
      columns: [
        { title: 'Immatriculation', data: 'vehicule.immatriculationProvisoire' },
        { title: 'Numéro de police', data: 'numeroPolice' },
        { title: 'Numéro assurance', data: 'codeAssurance' },
        { title: 'Compagnie', data: 'compagnieAssurance.nom' },
        { title: 'Date début', data: 'dateDebut' },
        { title: 'Date Fin', data: 'dateFin' },
        { title: 'Etat', data: 'etat' },
        {
          title: 'Date Création',
          data: 'dateCreation',
          render: function (data: string) {
            const date = new Date(data);
            return date.toLocaleDateString('fr-FR', {
              year: 'numeric',
              month: 'long',
              day: 'numeric'
            });
          }
        },
        {
          title: 'Action',
          data: 'documents',
          defaultContent: '',
          width: '100px',
          className: 'text-center',
          render: (data: any) => `
                    <button class="btn btn-link-primary btn-view" title="View" id="${data[0].lien}">
                        <i class="ti ti-download"></i>
                    </button>
                `
        }
      ],
      responsive: true,
      select: false,

    };


  }
  ngAfterViewInit(): void {
    // Access DataTables API instance after view initialization
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      // Use jQuery event delegation to handle dynamically added elements
      $('#tableAssurance tbody').on('click', '.btn-view', (event) => {
        //this.navigateToRoute(event.currentTarget.id);
        const url = this.baseUrl + event.currentTarget.id;
        // Open the URL in a new blank window/tab
        window.open(url, '_blank');
      });

    });
  }
}
