import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ListeMaintenanceComponent } from './liste-maintenance/liste-maintenance.component';
import { CreationFicheMaintenanceComponent } from './creation-fiche-maintenance/creation-fiche-maintenance.component';
import { MaintenanceDetailComponent } from './maintenance-detail/maintenance-detail.component';

const routes: Routes = [
  {
    path: 'liste-maintenance',
    component: ListeMaintenanceComponent
  },
  {
    path: 'Ajout_maintenance',
    component: CreationFicheMaintenanceComponent
  },
  {
    path: 'Detail-maintenance',
    component: MaintenanceDetailComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class GestionMaintenanceRoutingModule { }
