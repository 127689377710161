import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { GestionVehiculeRoutingModule } from './gestion-vehicule-routing.module';


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    GestionVehiculeRoutingModule
  ]
})
export class GestionVehiculeModule { }
