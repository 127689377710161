import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { trigger, state, style, animate, transition } from '@angular/animations';

// project import
import { SharedModule } from 'src/app/theme/shared/shared.module';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { VenteVehiculeReponse } from '../reponse/vente.vehicule.reponse';
import { VenteVehiculeService } from '../service/vente.vehicule.service';

@Component({
  selector: 'app-vente-vehicule-detail',
  standalone: true,
  imports: [CommonModule, SharedModule],
  templateUrl: './vente-vehicule-detail.component.html',
  styleUrl: './vente-vehicule-detail.component.scss',
  animations: [
    trigger('fade', [
      state('in', style({ opacity: 1 })),
      transition(':enter', [style({ opacity: 0 }), animate(600)]),
      transition(':leave', animate(600, style({ opacity: 0 })))
    ])
  ]
})
export class VenteVehiculeDetailComponent implements OnInit{
    // public props
    id: string | null = null;
    vehicule$!: Observable<VenteVehiculeReponse | null>;


    constructor(public router: Router,private route: ActivatedRoute,private venteVehiculeService: VenteVehiculeService) {}

    ngOnInit() {
      this.id = this.route.snapshot.paramMap.get('id');
      this.findDetail();
    }


    findDetail() {
      this.vehicule$=this.venteVehiculeService.getById(this.id);
    }
}
