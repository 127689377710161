import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ListeDocComponent } from './liste-doc/liste-doc.component';
import { AjoutDocComponent } from './ajout-doc/ajout-doc.component';
import { DetailDocComponent } from './detail-doc/detail-doc.component';

const routes: Routes = [
  {
    path: 'listes-documents',
    component: ListeDocComponent
  },
  {
    path: 'Ajout_document',
    component: AjoutDocComponent
  },
  {
    path: 'Detail-document',
    component: DetailDocComponent 
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class GestionDocumentRoutingModule { }
 