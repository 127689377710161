import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { SessionService } from 'src/app/Pages/auth/services/session.service';
import { CategorieModele } from '../modeles/categorie.modele';
import { CategorieReponse } from '../reponse/categorie.reponse';
@Injectable({
  providedIn: 'root',
})
export class CategorieService {
  private url: string = `${environment.BaseUrl}`;
  private key: string = `${environment.key}`;
  constructor(private http: HttpClient, private localStorageService: SessionService) { }
  private token: string = 'Bearer ' + this.localStorageService.getData('token');
  // Définition des options HTTP avec en-têtes
  private getHttpOptions(): { headers: HttpHeaders } {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept-Language': 'fr-FR',
        'Authorization': this.token,
        //'X-KEY': this.key,
      }),
    };
  }

  create(payload: CategorieModele): Observable<CategorieReponse> {
    return this.http.post<any>(
      `${this.url}/categories`,
      payload,
      this.getHttpOptions()
    );
  }

  update(payload: CategorieModele, id: any): Observable<CategorieReponse> {
    return this.http.put<any>(
      `${this.url}/categories/${id}`,
      payload,
      this.getHttpOptions()
    );
  }

  delete(id: any): Observable<CategorieReponse> {
    return this.http.delete<any>(
      `${this.url}/categories/${id}`,
      this.getHttpOptions()
    );
  }

  getById(id: any): Observable<CategorieReponse> {
    return this.http.get<any>(`${this.url}/categories/${id}`, this.getHttpOptions());
  }

  getAll(query?: any): Observable<CategorieReponse[]> {
    const url = query ? `${this.url}/categories?${query}` : `${this.url}/categories`;
    return this.http.get<any>(
      url,
      this.getHttpOptions()
    );
  }
}
