import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AbstractControl, FormBuilder, FormGroup, ValidatorFn, Validators } from '@angular/forms';
// project import
import { SharedModule } from 'src/app/theme/shared/shared.module';
// third party
import { matches } from 'lodash'; 

@Component({
  selector: 'app-creation-fiche-visite',
  standalone: true,
  imports: [CommonModule, SharedModule],
  templateUrl: './creation-fiche-visite.component.html',
  styleUrl: './creation-fiche-visite.component.scss'
})
export class CreationFicheVisiteComponent {
  pwForm: FormGroup;

  constructor(private fb: FormBuilder) {
    this.pwForm = fb.group({
      pw: fb.control('', [Validators.required]),
      check: fb.control('', [Validators.required])
    });

    const pwControl = this.pwForm.get('pw');
    const checkControl = this.pwForm.get('check');

    if (pwControl && checkControl) {
      this.pwForm.addValidators(matchValidator(pwControl, checkControl));
    }
  };


}
  function matchValidator(control: AbstractControl, controlTwo: AbstractControl): ValidatorFn {
    return () => {
      if (control.value !== controlTwo.value) return { match_error: 'Password does not match' };
      return matches;
    };
  } 