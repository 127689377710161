import { Component, ElementRef} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';


import listStyleData from 'src/fake-data/list-style-1.json';
import { SharedModule } from 'src/app/theme/shared/shared.module';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { DetailAssComponent } from "../detail-ass/detail-ass.component";

interface person {
  number: string;
  src: string;
  name: string;
  email: string;
  country: string;
  friend: string;
  follower: string;
  status: string;
  color: string;
} 

@Component({
  selector: 'app-liste-ass',
  standalone: true,
  imports: [CommonModule, SharedModule, RouterModule, DetailAssComponent],
  templateUrl: './liste-ass.component.html',
  styleUrl: './liste-ass.component.scss'
})
export class ListeAssComponent {
  PersonList: person[] = listStyleData;

  // private Props
  closeResult = '';

  // Constructor
  constructor(private modalService: NgbModal) {}

  // private Method

  openLg(content6: ElementRef) {
    this.modalService.open(content6, { size: 'lg' });
}
}
 