import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CreationVComponent } from './creation-v/creation-v.component';
import { ListVComponent } from './list-v/list-v.component';
import { VehiculeDetailComponent } from './vehicule-detail/vehicule-detail.component';

const routes: Routes = [
  {
    path: 'Nos_vehicule',
    component: ListVComponent
  },
  {
    path: 'Ajout_vehicule',
    component: CreationVComponent
  },
  {
    path: 'Detail-vehicule/:id',
    component: VehiculeDetailComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class VehiculeRoutingModule { }
