<div class="row">
  <div class="col-sm-12">
    <app-card cardTitle="Types de véhicule">
      <div *ngIf="hasSuccess" class="alert alert-success mt-2 mb-2">
        <p>{{successMessage}}</p>
      </div>
      <div *ngIf="hasError" class="alert alert-danger mt-2 mb-2">
        <p>{{errorMessage}}</p>
      </div>

      <form [formGroup]="typeForm" (ngSubmit)="onSubmit()">
        <div class="form-group">
          <div class="row">
            <div class="col-md-6"></div>
            <div class="col-md-6">
              <label for="libelle"
                >libelle <span class="text-danger">*</span></label
              >
              <input
                type="text"
                formControlName="libelle"
                class="form-control"
                id="libelle"
                [ngClass]="{
                  'is-invalid':
                    typeForm.get('libelle')?.invalid &&
                    typeForm.get('libelle')?.touched
                }"
              />
              <div
                *ngIf="
                  typeForm.get('libelle')?.invalid &&
                  typeForm.get('libelle')?.touched
                "
                class="invalid-feedback"
              >
                <div *ngIf="typeForm.get('libelle')?.errors?.['required']">
                  Le libelle est requis.
                </div>
                <div *ngIf="typeForm.get('libelle')?.errors?.['minlength']">
                  Le libelle doit contenir au moins 3 caractères.
                </div>
              </div>

              <div class="form-group">
                <label for="description"
                  >Description</label
                >
                <input
                  type="text"
                  formControlName="description"
                  class="form-control"
                  id="description"
                  [ngClass]="{
                    'is-invalid':
                      typeForm.get('description')?.invalid &&
                      typeForm.get('description')?.touched
                  }"
                />
                <div
                  *ngIf="
                    typeForm.get('description')?.invalid &&
                    typeForm.get('description')?.touched
                  "
                  class="invalid-feedback"
                >
                  <div
                    *ngIf="typeForm.get('description')?.errors?.['required']"
                  >
                    La description est requise.
                  </div>
                </div>
              </div>
               <div class=" d-flex justify-content-end">
              <button
                *ngIf="isLoading"
                class="btn btn-primary"
                type="submit"
                disabled
              >
                <span class="spinner-grow spinner-grow-sm" role="status"></span>
                Traitement...
              </button>

              <!-- Bouton "Enregistrer" -->
              <button
                type="submit"
                class="btn btn-primary"
                [disabled]="typeForm.invalid"
                *ngIf="!isLoading"
              >
                Enregistrer
              </button>
            </div>
            </div>
          </div>
        </div>
      </form>
       <div class="mb-5"></div>
      <app-liste-t></app-liste-t>
    </app-card>
  </div>
</div>
