<div class="auth-main">
  <div class="auth-wrapper v1">
    <div class="auth-form">
      <div class="card mt-5">
        <div class="card-body">
          <div class="row">
            <div class="d-flex justify-content-between">
              <div>
                <h2 class="text-secondary"><b>Bonjour, Bienvenue</b></h2>
                <h4>Se connecter à son compte</h4>
              </div>
              <a href="javascript:">
                <img src="assets/images/logo.svg" alt="theme-logo" />
              </a>
            </div>
          </div>

          <div class="saprator mt-3">

          </div>

          <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
            <div class="form-floating mb-3">
              <input
                type="email"
                id="email"
                formControlName="email"
                class="form-control"
              />
              <label for="email">Email address / Username</label>
              <div *ngIf="!email?.valid && (email?.dirty ||email?.touched)">
                <div [hidden]="!email?.errors?.['required']" class="text-danger">
                 L'email est requis
                </div>
                <div [hidden]="!email?.errors?.['pattern']" class="text-danger">
                  Email invalid
                </div>
              </div>
            </div>
            <div class="form-floating mb-3">
              <input
                type="password"
                id="password"
                formControlName="password"
                class="form-control"
              />
              <label for="Password">Mot de Passe</label>
              <i class="ti ti-eye" id="togglePassword"></i>
              <div *ngIf="!password?.valid && (password?.dirty ||password?.touched)">
                <div [hidden]="!password?.errors?.['required']" class="text-danger">
                  Le mot de passe est requis
                 </div>
              </div>
            </div>
            <div class="d-flex mt-1 justify-content-between">
              <div class="form-check">
                <input class="form-check-input input-primary" type="checkbox" id="customCheckc1" checked="" />
                <label class="form-check-label text-muted" for="customCheckc1">Se souvenir de moi</label>
              </div>
              <h5 class="text-secondary">
                <a [routerLink]="['/auth/forget']">Mot de passe oublié?</a>
              </h5>
            </div>
            <div class="d-grid mt-4">
              <button *ngIf="isLoading" class="btn btn-secondary" type="button" disabled>
                <span class="spinner-grow spinner-grow-sm" role="status"></span>
                Traitement...
              </button>
                <button *ngIf="!isLoading"class="btn btn-secondary " [disabled]="!loginForm.valid">Se connecter</button>
      
            </div>
          </form>
          <hr />

        </div>
      </div>
    </div>
    <div class="auth-sidecontent">
      <div class="p-3 px-lg-5 text-center">
        <div class="animation-content">
          <img src="assets/images/authentication/authentication-blue-card.svg" alt="images" class="img-fluid blue-card mt-3" />
          <img src="assets/images/authentication/authentication-purple-card.svg" alt="images" class="img-fluid purple-card mt-3" />
        </div>
        <ngb-carousel class="carousel-dark" [showNavigationArrows]="false" [showNavigationIndicators]="true">
          <ng-template ngbSlide>
            <h1><b>Système de Gestion de Parc Auto</b></h1>
            <p class="f-12 mt-4">Gestion de locations et de ventes</p>
          </ng-template>
          <ng-template ngbSlide>
            <h1><b>Système de Gestion de Vente de pièces</b></h1>
            <p class="f-12 mt-4">Ventes avec gestion de stock</p>
          </ng-template>
          <ng-template ngbSlide>
            <h1><b>Système de Gestion des Chauffeurs</b></h1>
            <p class="f-12 mt-4">Gestion des Chauffeurs</p>
          </ng-template>
        </ngb-carousel>
      </div>
    </div>
  </div>
</div>

