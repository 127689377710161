import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { GestionDocumentRoutingModule } from './gestion-document-routing.module';


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    GestionDocumentRoutingModule
  ]
})
export class GestionDocumentModule { }
