<div class="row">
  <div class="col-sm-12">
    <app-card cardTitle="Modèles de véhicule">
      <div *ngIf="hasSuccess" class="alert alert-success mt-2 mb-2">
        <p>{{successMessage}}</p>
      </div>
      <div *ngIf="hasError" class="alert alert-danger mt-2 mb-2">
        <p>{{errorMessage}}</p>
      </div>
      <form [formGroup]="modelForm" (ngSubmit)="onSubmit()">
        <div class="row">
          <div class="col-md-6">
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label for="marque">Marque <span class="text-danger">*</span></label>
              <select
                formControlName="idMarque"
                class="form-select"
                id="idMarque"
                [ngClass]="{'is-invalid': modelForm.get('idMarque')?.invalid && modelForm.get('idMarque')?.touched}"
              >
                <option value="">Selectionner</option>
                <option *ngFor="let marque of marques" [value]="marque.idMarque">{{ marque.libelle }}</option>
              </select>
              <div *ngIf="modelForm.get('idMarque')?.invalid && modelForm.get('idMarque')?.touched" class="invalid-feedback">
                La marque est requise.
              </div>
            </div>
            <div class="form-group">
              <label for="libelle">Libellé <span class="text-danger">*</span></label>
              <input
                type="text"
                formControlName="libelle"
                class="form-control"
                id="libelle"
                [ngClass]="{'is-invalid': modelForm.get('libelle')?.invalid && modelForm.get('libelle')?.touched}"
              />
              <div *ngIf="modelForm.get('libelle')?.invalid && modelForm.get('libelle')?.touched" class="invalid-feedback">
                <div *ngIf="modelForm.get('libelle')?.errors?.['required']">Le libellé est requis.</div>
                <div *ngIf="modelForm.get('libelle')?.errors?.['minlength']">Le libellé doit contenir au moins 3 caractères.</div>
              </div>
            </div>

            <div class="form-group">
              <label for="description">Description du modèle</label>
              <input
                type="text"
                formControlName="description"
                class="form-control"
                id="description"
              />
            </div>
          </div>
        </div>

        <div class="d-flex justify-content-end">
          <button
                *ngIf="isLoading"
                class="btn btn-primary"
                type="submit"
                disabled
              >
                <span class="spinner-grow spinner-grow-sm" role="status"></span>
                Traitement...
              </button>

              <!-- Bouton "Enregistrer" -->
              <button
                type="submit"
                class="btn btn-primary"
                [disabled]="modelForm.invalid"
                *ngIf="!isLoading"
              >
                Enregistrer
              </button>
        </div>
      </form>
      <div class="mb-5"></div>
      <app-liste-md></app-liste-md>
    </app-card>
  </div>
</div>
