<div class="auth-main">
  <div class="auth-wrapper v3">
    <div class="auth-form">
      <div class="card mt-5">
        <div class="card-body">
          <div class="row">
            <div class="d-flex justify-content-center">
              <div class="text-center">
                <h2 class="text-secondary mt-5">
                  <b>Entrer votre Code de vérification</b>
                </h2>
                <h4 class="mt-3">Nous vous avons envoyer un mail.</h4>
              </div>
            </div>
          </div>
          <div class="d-flex justify-content-center">
            <p class="f-14 mt-2"> Nous avons envoyer un code sur john.****&#64;company.com </p>
          </div>
          <div class="d-flex justify-content-between">
            <div class="d-flex align-items-center">
              <input
                aria-label="Please enter verification code. Character 1"
                autocomplete="off"
                class=""
                type="text"
                maxlength="1"
                value=""
                style="
                  width: 100%;
                  text-align: center;
                  margin: 8px;
                  padding: 10px;
                  border: 1px solid rgb(224, 224, 224);
                  border-radius: 4px;
                "
              />
            </div>
            <div class="d-flex align-items-center">
              <input
                aria-label="Please enter verification code. Character 1"
                autocomplete="off"
                class=""
                type="text"
                maxlength="1"
                value=""
                style="
                  width: 100%;
                  text-align: center;
                  margin: 8px;
                  padding: 10px;
                  border: 1px solid rgb(224, 224, 224);
                  border-radius: 4px;
                "
              />
            </div>
            <div class="d-flex align-items-center">
              <input
                aria-label="Please enter verification code. Character 1"
                autocomplete="off"
                class=""
                type="text"
                maxlength="1"
                value=""
                style="
                  width: 100%;
                  text-align: center;
                  margin: 8px;
                  padding: 10px;
                  border: 1px solid rgb(224, 224, 224);
                  border-radius: 4px;
                "
              />
            </div>
            <div class="d-flex align-items-center">
              <input
                aria-label="Please enter verification code. Character 1"
                autocomplete="off"
                class=""
                type="text"
                maxlength="1"
                value=""
                style="
                  width: 100%;
                  text-align: center;
                  margin: 8px;
                  padding: 10px;
                  border: 1px solid rgb(224, 224, 224);
                  border-radius: 4px;
                "
              />
            </div>
          </div>
          <div class="d-grid mt-4">
            <button type="button" class="btn btn-primary p-2">Continue</button>
          </div>
          <div class="d-flex mt-1 justify-content-between mt-4">
            <p class="text-muted"> Avez vous réçu un Email ? vérifier votre spam, où </p>
            <p class="text-primary">Renvoyer le code</p>
          </div>
          <hr />
          <h5 class="d-flex justify-content-center"> Avez vous réçu un Email ? vérifier votre spam, où </h5>
          <div class="d-grid mt-4">
            <button type="button" class="btn btn-outline-secondary bg-light text-secondary p-2"> Renvoyer le code </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
