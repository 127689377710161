// format-number.pipe.ts
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatNumber',
  standalone: true // Ajoutez cette ligne
})
export class FormatNumberPipe implements PipeTransform {
  transform(value: number): string {
    return new Intl.NumberFormat('fr-FR').format(value);
  }
}
