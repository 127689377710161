import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { GestionAssuranceRoutingModule } from './gestion-assurance-routing.module';


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    GestionAssuranceRoutingModule
  ]
})
export class GestionAssuranceModule { }
