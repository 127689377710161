import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CreationTComponent } from './creation-t/creation-t.component';
import { ListeTComponent } from './liste-t/liste-t.component';
import { TypeDetailComponent } from './type-detail/type-detail.component';

const routes: Routes = [
  {
    path: 'Nos_types',
    component: ListeTComponent
  },
  {
    path: 'Ajout_types',
    component: CreationTComponent
  },
  {
    path: 'Types_details',
    component: TypeDetailComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class TypeRoutingModule { }
