import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, RouterModule } from '@angular/router';
import { Observable } from 'rxjs';
import { SharedModule } from 'src/app/theme/shared/shared.module';
import { ClientService } from './service/client.service';
import { ClientModele } from './modeles/client.modele';
import { ClientReponse } from './reponse/client.reponse';
import { TableClientComponent } from "./table-client/table-client.component";

@Component({
  selector: 'app-client-liste',
  standalone: true,
  imports: [CommonModule,TableClientComponent, SharedModule, RouterModule, TableClientComponent],
  templateUrl: './client.component.html',
  styleUrl: './client.component.scss',
})
export class ClientComponent implements OnInit {
  clientForm: FormGroup;
  isLoading: boolean = false;
  dtRouterLinkOptions: object = {};
  hasError: boolean = false;
  hasSuccess: boolean = false;
  errorMessage: any;
  successMessage: any;


  constructor(
    private router: Router,
    private fb: FormBuilder,
    private clientService: ClientService
  ) {
    this.clientForm = this.fb.group({
      nom: ['', [Validators.required]],
      nomEntreprise: [''],
      prenoms: ['', [Validators.required]],
      email: [[''], [Validators.required]],
      adresse: [[''], [Validators.required]],
      telephone: [[''], [Validators.required]],
    });
  }

  // Méthode appelée à la soumission du formulaire
  onSubmit() {
    this.isLoading = true;
    this.hasError = false;
    this.hasSuccess = true;
    if (this.clientForm.valid) {
      const payload: ClientModele = this.clientForm.value;
      this.clientService.create(payload).subscribe(
        (response: ClientReponse) => {
          this.isLoading = false;
          this.hasSuccess = true;
          this.successMessage = 'Client ajouté avec succès.';
          this.clientForm.reset();
        },
        (error: any) => {
          this.hasError = true;
          this.isLoading = false;
          this.errorMessage = error.error.message;
          console.error('Erreur lors de la création du Client', error);
        }
      );
    } else {
      console.log('Le formulaire est invalide');
    }
  }

  // Méthode pour envoyer la requête


  ngOnInit(): void {

  }
}
