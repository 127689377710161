import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { SharedModule } from 'src/app/theme/shared/shared.module';
import { ModelService } from '../services/model.service';
import { Observable } from 'rxjs';
import { MarqueService } from '../../marque/services/marque.service';
import { MarqueReponse } from '../../marque/reponses/marque.reponse';
import { ListeMdComponent } from "../liste-md/liste-md.component";

@Component({
  selector: 'app-creation-md',
  standalone: true,
  imports: [CommonModule,ListeMdComponent, SharedModule, RouterModule, ListeMdComponent],
  templateUrl: './creation-md.component.html',
  styleUrl: './creation-md.component.scss'
})
export class CreationMdComponent implements OnInit{
  modelForm: FormGroup;
  isLoading = false;
  marques:MarqueReponse[]=[];
  hasError: boolean = false;
  hasSuccess: boolean = false;
  errorMessage: any;
  successMessage: any;

  constructor(private fb: FormBuilder, private modelService: ModelService,private marqueService: MarqueService) {
    this.modelForm = this.fb.group({
      libelle: ['', [Validators.required, Validators.minLength(3)]],
      description: ['', Validators.required],
      idMarque: ['', Validators.required]
    });
  }

  ngOnInit(): void {
    this.findMarques().subscribe(
      response => {
        console.log('Liste des marques', response);
        this.marques = response;
      }
    );
  }

  onSubmit() {
    this.isLoading=true;
    if (this.modelForm.valid) {
      const payload = this.modelForm.value;
      this.createModel(payload).subscribe(
        response => {
          this.isLoading = false;
          this.hasSuccess = true;
          this.successMessage = 'Modele de véhicule ajouté avec succès.';
          this.modelForm.reset();
        },
        error => {
          this.hasError = true;
          this.isLoading = false;
          this.errorMessage = error.error.message;
          console.error('Erreur lors de l\'enregistrement du modèle', error);
        }
      );
    } else {
      console.log('Le formulaire est invalide');
    }
  }

  // Méthode pour envoyer la requête de création du modèle
  createModel(payload: any): Observable<any> {
    return this.modelService.create(payload);
  }

  findMarques(query?:any): Observable<any> {
    return this.marqueService.getAll(query);
  }
}
