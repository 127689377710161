<div id="styleSelector" class="menu-styler" [ngClass]="{ open: styleSelectorToggle }">
  <div class="pct-c-btn">
    <a href="javascript:" (click)="styleSelectorToggle = !styleSelectorToggle">
      <i class="ph-duotone ph-gear-six"></i>
    </a>
  </div>
  <div class="style-block">
    <div class="customize-header">
      <h5 class="text-uppercase">Theme Customization</h5>
      <div class="d-inline-flex align-items-center gap-2">
        <button type="button" class="btn btn-sm rounded btn-outline-danger" id="layoutreset" (click)="setResetLayout('reset')"
          >Reset</button
        >
        <a type="button" class="avtar avtar-s btn-link-danger btn-pc-default" (click)="styleSelectorToggle = !styleSelectorToggle"
          ><i class="ti ti-x f-20"></i
        ></a>
      </div>
    </div>
    <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav nav-tabs nav-fill pct-tabs">
      <li [ngbNavItem]="1">
        <button class="nav-link" ngbNavLink type="button" ngbTooltip="Layout Settings"><i class="ti ti-color-swatch"></i></button>
        <ng-template ngbNavContent>
          <ng-scrollbar style="height: calc(100vh - 140px)" visibility="hover">
            <ul class="list-group list-group-flush">
              <li class="list-group-item">
                <div class="pc-dark">
                  <div class="d-flex align-items-center">
                    <div class="flex-grow-1 me-3">
                      <h5 class="mb-1 text-uppercase">Theme Mode</h5>
                      <p class="text-muted text-sm mb-0">Light / Dark / System</p>
                    </div>
                    <div class="flex-shrink-0">
                      <div class="row g-2 theme-color theme-layout">
                        <div class="col-6">
                          <div class="d-grid">
                            <button
                              class="preset-btn btn size"
                              [ngClass]="{ active: themeMode === false }"
                              (click)="setDarkLayout(false)"
                              ngbTooltip="Light"
                            >
                              <img src="assets/images/customization/sun.svg" alt="img" class="wid-30 hei-30" />
                            </button>
                          </div>
                        </div>
                        <div class="col-6">
                          <div class="d-grid">
                            <button
                              class="preset-btn btn size bg-dark"
                              [ngClass]="{ active: themeMode === true }"
                              (click)="setDarkLayout(true)"
                              ngbTooltip="Dark"
                            >
                              <img src="assets/images/customization/moon-filled.svg" alt="img" class="wid-30 hei-30" />
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <li class="list-group-item">
                <h5 class="mb-1 text-uppercase">Accent color</h5>
                <p class="text-muted text-sm mb-2">Choose your primary theme color</p>
                <div class="theme-color preset-color">
                  <a
                    href="javascript:"
                    title="preset-1"
                    [ngClass]="{ active: bodyColor === 'preset-1' }"
                    (click)="SetBodyColor('preset-1')"
                    data-value="preset-1"
                  >
                    <i class="ti ti-check"></i>
                  </a>
                  <a
                    href="javascript:"
                    title="preset-2"
                    [ngClass]="{ active: bodyColor === 'preset-2' }"
                    (click)="SetBodyColor('preset-2')"
                    data-value="preset-2"
                    data-value="preset-2"
                    ><i class="ti ti-check"></i
                  ></a>
                  <a
                    href="javascript:"
                    title="preset-2"
                    [ngClass]="{ active: bodyColor === 'preset-3' }"
                    (click)="SetBodyColor('preset-3')"
                    data-value="preset-3"
                    ><i class="ti ti-check"></i
                  ></a>
                  <a
                    href="javascript:"
                    title="preset-4"
                    [ngClass]="{ active: bodyColor === 'preset-4' }"
                    (click)="SetBodyColor('preset-4')"
                    data-value="preset-4"
                    ><i class="ti ti-check"></i
                  ></a>
                  <a
                    href="javascript:"
                    title="preset-5"
                    [ngClass]="{ active: bodyColor === 'preset-5' }"
                    (click)="SetBodyColor('preset-5')"
                    data-value="preset-5"
                    ><i class="ti ti-check"></i
                  ></a>
                  <a
                    href="javascript:"
                    title="preset-6"
                    [ngClass]="{ active: bodyColor === 'preset-6' }"
                    (click)="SetBodyColor('preset-6')"
                    data-value="preset-6"
                    ><i class="ti ti-check"></i
                  ></a>
                  <a
                    href="javascript:"
                    title="preset-7"
                    [ngClass]="{ active: bodyColor === 'preset-7' }"
                    (click)="SetBodyColor('preset-7')"
                    data-value="preset-7"
                    ><i class="ti ti-check"></i
                  ></a>
                </div>
              </li>
              <li class="list-group-item">
                <div class="d-flex align-items-center">
                  <div class="flex-grow-1 me-3">
                    <h5 class="mb-1 text-uppercase">Sidebar Caption</h5>
                    <p class="text-muted text-sm mb-0">Caption Hide / Show</p>
                  </div>
                  <div class="flex-shrink-0">
                    <div class="row g-2 theme-color theme-nav-caption">
                      <div class="col-6">
                        <div class="d-grid">
                          <button
                            class="preset-btn btn"
                            data-value="false"
                            [ngClass]="{ active: sidebar_caption_hide === false }"
                            (click)="captionShow(false)"
                            ngbTooltip="Caption Show"
                          >
                            <span class="pc-lay-icon"
                              ><span></span><span></span><span><span></span><span></span></span><span></span
                            ></span>
                          </button>
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="d-grid">
                          <button
                            class="preset-btn btn"
                            data-value="true"
                            [ngClass]="{ active: sidebar_caption_hide === true }"
                            (click)="captionShow(true)"
                            ngbTooltip="Caption Hide"
                          >
                            <span class="pc-lay-icon"
                              ><span></span><span></span><span><span></span><span></span></span><span></span
                            ></span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <li class="list-group-item">
                <div class="pc-rtl">
                  <div class="d-flex align-items-center">
                    <div class="flex-grow-1 me-3">
                      <h5 class="mb-1 text-uppercase">Theme Layout</h5>
                      <p class="text-muted text-sm">LTR/RTL</p>
                    </div>
                    <div class="flex-shrink-0">
                      <div class="row g-2 theme-color theme-direction">
                        <div class="col-6">
                          <div class="d-grid">
                            <button
                              class="preset-btn btn"
                              [ngClass]="{ active: rtlLayout === false }"
                              (click)="setRtlLayout(false)"
                              ngbTooltip="LTR"
                            >
                              <img src="assets/images/customization/ltr.svg" alt="img" />
                            </button>
                          </div>
                        </div>
                        <div class="col-6">
                          <div class="d-grid">
                            <button
                              class="preset-btn btn"
                              [disabled]="layout === 'horizontal'"
                              [ngClass]="{ active: rtlLayout === true }"
                              (click)="setRtlLayout(true)"
                              ngbTooltip="RTL"
                            >
                              <img src="assets/images/customization/rtl.svg" alt="img" />
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <li class="list-group-item pc-box-width">
                <div class="pc-container-width">
                  <div class="d-flex align-items-center">
                    <div class="flex-grow-1 me-3">
                      <h5 class="mb-1 text-uppercase">Layout Width</h5>
                      <p class="text-muted text-sm">Full / Fixed width</p>
                    </div>
                    <div class="flex-shrink-0">
                      <div class="row g-2 theme-color theme-container">
                        <div class="col-6">
                          <div class="d-grid">
                            <button
                              class="preset-btn btn size"
                              [ngClass]="{ active: boxContainer === false }"
                              (click)="setBoxContainer(false)"
                              ngbTooltip="Full Width"
                            >
                              <img src="assets/images/customization/big.svg" alt="img" class="wid-30 hei-30" />
                            </button>
                          </div>
                        </div>
                        <div class="col-6">
                          <div class="d-grid">
                            <button
                              class="preset-btn btn size"
                              [ngClass]="{ active: boxContainer === true }"
                              (click)="setBoxContainer(true)"
                              ngbTooltip="Fixed Width"
                            >
                              <img src="assets/images/customization/small.svg" alt="img" class="wid-30 hei-30" />
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <li class="list-group-item">
                <div class="pc-rtl">
                  <div class="d-flex align-items-center">
                    <div class="flex-grow-1 me-3">
                      <h5 class="mb-1 text-uppercase">Menu Orientation</h5>
                      <p class="text-muted text-sm">Vertical / Horizontal</p>
                    </div>
                    <div class="flex-shrink-0">
                      <div class="row g-2 theme-color theme-direction">
                        <div class="col-4">
                          <div class="d-grid">
                            <button
                              class="preset-btn btn"
                              [ngClass]="{ active: layout === 'vertical' }"
                              (click)="setMenuOrientation('vertical')"
                              ngbTooltip="Vertical"
                            >
                              <img src="assets/images/customization/vertical.svg" alt="img" />
                            </button>
                          </div>
                        </div>
                        <div class="col-4">
                          <div class="d-grid">
                            <button
                              class="preset-btn btn"
                              [disabled]="rtlLayout === true"
                              [ngClass]="{ active: layout === 'horizontal' }"
                              (click)="setMenuOrientation('horizontal')"
                              ngbTooltip="horizontal"
                            >
                              <img src="assets/images/customization/horizontal.svg" alt="img" />
                            </button>
                          </div>
                        </div>
                        <div class="col-4">
                          <div class="d-grid">
                            <button
                              class="preset-btn btn"
                              [ngClass]="{ active: layout === 'compact' }"
                              (click)="setMenuOrientation('compact')"
                              ngbTooltip="compact"
                            >
                              <img src="assets/images/customization/mini.svg" alt="img" />
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </ng-scrollbar>
        </ng-template>
      </li>
      <li [ngbNavItem]="2">
        <button class="nav-link" ngbNavLink type="button" ngbTooltip="Font Settings"><i class="ti ti-typography"></i></button>
        <ng-template ngbNavContent>
          <ng-scrollbar style="height: calc(100vh - 140px)" visibility="hover">
            <ul class="list-group list-group-flush">
              <li class="list-group-item">
                <h5 class="mb-1 text-uppercase">Font Style</h5>
                <p class="text-muted text-sm">Choose theme font</p>
                <div class="theme-color theme-font-style">
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="radio"
                      name="layout_font"
                      id="layoutfontRoboto"
                      checked
                      [checked]="setFontFamily === 'Roboto'"
                      (click)="fontFamily('Roboto')"
                      ngbTooltip="Roboto"
                    />
                    <label class="form-check-label" for="layoutfontRoboto"> Roboto </label>
                  </div>
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="radio"
                      name="layout_font"
                      id="layoutfontPoppins"
                      [checked]="setFontFamily === 'Poppins'"
                      (click)="fontFamily('Poppins')"
                      ngbTooltip="Poppins"
                    />
                    <label class="form-check-label" for="layoutfontPoppins"> Poppins </label>
                  </div>
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="radio"
                      name="layout_font"
                      id="layoutfontInter"
                      [checked]="setFontFamily === 'Inter'"
                      (click)="fontFamily('Inter')"
                      ngbTooltip="Inter"
                    />
                    <label class="form-check-label" for="layoutfontInter"> Inter </label>
                  </div>
                </div>
              </li>
            </ul>
          </ng-scrollbar>
        </ng-template>
      </li>
    </ul>
    <div [ngbNavOutlet]="nav" class="pct-body customizer-body"></div>
  </div>
</div>
