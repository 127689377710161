<div class="row">
  <!-- [ sample-page ] start -->
  <div class="col-xl-4 col-md-6 border-black-1">
    <div class="card bg-secondary-dark dashnum-card text-white overflow-hidden">
      <span class="round small"></span>
      <span class="round big"></span>
      <div class="card-body">
        <div class="row">
          <div class="col">
            <div class="avtar avtar-lg">
              <i class="text-white ti ti-credit-card"></i>
            </div>
          </div>
          <div class="col-auto">
            <div class="btn-group" ngbDropdown>
              <a type="button" class="avtar bg-secondary dropdown-toggle arrow-none" ngbDropdownToggle>
                <i class="ti ti-dots"></i>
              </a>
              <ul class="dropdown-menu dropdown-menu-end" ngbDropdownMenu>
                <li><button class="dropdown-item" type="button">Import Card</button></li>
                <li><button class="dropdown-item" type="button">Export</button></li>
              </ul>
            </div>
          </div>
        </div>
        <span class="text-white d-block f-34 f-w-500 my-2">$500.00 <i class="ti ti-arrow-up-right-circle opacity-50"></i></span>
        <p class="mb-0 opacity-50">Total Earning</p>
      </div>
    </div>
  </div>
  <div class="col-xl-4 col-md-6">
    <div class="card bg-primary-dark dashnum-card text-white overflow-hidden">
      <span class="round small"></span>
      <span class="round big"></span>
      <div class="card-body">
        <div class="row">
          <div class="col">
            <div class="avtar avtar-lg">
              <i class="text-white ti ti-credit-card"></i>
            </div>
          </div>
          <div class="col-auto">
            <ul ngbNav aria-busy="true" class="nav-tabs nav-pills" #nav="ngbNav" (navChange)="onNavChange($event)">
              <li class="nav-item" [ngbNavItem]="1">
                <a class="nav-link text-white" ngbNavLink>Month</a>
                <ng-template ngbNavContent>
                  <div class="row">
                    <div class="col-6">
                      <span class="text-white d-block f-34 f-w-500 my-2">$108 <i class="ti ti-arrow-up-right-circle opacity-50"></i></span>
                      <p class="mb-0 opacity-50">Total Earning</p>
                    </div>
                    <div class="col-6">
                      <div id="tab-chart-1"></div>
                    </div>
                  </div>
                </ng-template>
              </li>
              <li class="nav-item" [ngbNavItem]="2">
                <a class="nav-link text-white" ngbNavLink>Year</a>
                <ng-template ngbNavContent>
                  <div class="row">
                    <div class="col-6">
                      <span class="text-white d-block f-34 f-w-500 my-2"
                        >$961 <i class="ti ti-arrow-down-right-circle opacity-50"></i
                      ></span>
                      <p class="mb-0 opacity-50">C/W Last Year</p>
                    </div>
                    <div class="col-6">
                      <div id="tab-chart-2"></div>
                    </div>
                  </div>
                </ng-template>
              </li>
            </ul>
          </div>
        </div>
        <div [ngbNavOutlet]="nav"> </div>
      </div>
    </div>
  </div>
  <div class="col-xl-4 col-md-12">
    @for (item of profileCard; track item) {
      <div class="card dashnum-card dashnum-card-small overflow-hidden {{ item.style }}">
        <span class="round {{ item.background }} small"></span>
        <span class="round {{ item.background }} big"></span>
        <div class="card-body p-3">
          <div class="d-flex align-items-center">
            <div class="avtar avtar-lg {{ item.avatar_background }}">
              <i class="{{ item.color }} ti ti-credit-card"></i>
            </div>
            <div class="ms-2">
              <h4 class="{{ item.value_color }} mb-1">{{ item.value }} <i class="ti ti-arrow-up-right-circle opacity-50"></i></h4>
              <p class="mb-0 opacity-50 text-sm">{{ item.text }}</p>
            </div>
          </div>
        </div>
      </div>
    }
  </div>

  <div class="col-xl-8 col-md-12">
    <div class="card">
      <div class="card-body">
        <div class="row mb-3 align-items-center">
          <div class="col">
            <small>Total Growth</small>
            <h3>$2,324.00</h3>
          </div>
          <div class="col-auto">
            <select class="form-select p-r-35" title="Select">
              <option>Today</option>
              <option selected>This Month</option>
              <option>This Year</option>
            </select>
          </div>
        </div>
        <apx-chart
          [series]="chartOptions.series"
          [chart]="chartOptions.chart"
          [dataLabels]="chartOptions.dataLabels"
          [plotOptions]="chartOptions.plotOptions"
          [responsive]="chartOptions.responsive"
          [xaxis]="chartOptions.xaxis"
          [colors]="growthChart"
          [grid]="chartOptions.grid"
          [tooltip]="chartOptions.tooltip"
          [theme]="chartOptions.theme"
        ></apx-chart>
      </div>
    </div>
  </div>
  <div class="col-xl-4 col-md-12">
    <div class="card">
      <div class="card-body">
        <div class="row mb-3 align-items-center">
          <div class="col">
            <h4>Popular Stocks</h4>
          </div>
          <div class="col-auto"> </div>
        </div>
        <div class="rounded bg-light-secondary overflow-hidden mb-3">
          <div class="px-3 pt-3">
            <div class="row mb-1 align-items-start">
              <div class="col">
                <h5 class="text-secondary mb-0">Bajaj Finery</h5>
                <small class="text-muted">10% Profit</small>
              </div>
              <div class="col-auto">
                <h4 class="mb-0">$1839.00</h4>
              </div>
            </div>
          </div>
          <apx-chart
            [series]="chartOptions1.series"
            [chart]="chartOptions1.chart"
            [dataLabels]="chartOptions1.dataLabels"
            [plotOptions]="chartOptions1.plotOptions"
            [responsive]="chartOptions1.responsive"
            [colors]="bajajChart"
            [stroke]="chartOptions1.stroke"
            [theme]="chartOptions1.theme"
          ></apx-chart>
        </div>
        <ul class="list-group list-group-flush">
          @for (list of ListGroup; track list) {
            <li class="list-group-item px-0 {{ list.space }}">
              <div class="row align-items-start">
                <div class="col">
                  <h5 class="mb-0">{{ list.name }}</h5>
                  <small class="text-success">{{ list.profit }}</small>
                </div>
                <div class="col-auto">
                  <h4 class="mb-0">
                    {{ list.invest
                    }}<span class="ms-2 align-top avtar avtar-xxs {{ list.bgColor }}"
                      ><i class="{{ list.icon }} {{ list.color }}"></i
                    ></span>
                  </h4>
                </div>
              </div>
            </li>
          }
        </ul>
        <div class="text-center">
          <a href="javascript:" class="b-b-primary text-primary">View all <i class="ti ti-chevron-right"></i></a>
        </div>
      </div>
    </div>
  </div>
  <!-- [ sample-page ] end -->
</div>
