import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { FournisseurComponent } from './fournisseur/fournisseur.component';
import { ClientComponent } from './client/client.component';

const routes: Routes = [
  {
    path: 'liste-client',
    component: ClientComponent,
  },
  {
    path: 'liste-fournisseur',
    component: FournisseurComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class GestionPartenaireRoutingModule {}
