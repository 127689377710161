export interface NavigationItem {
  id: string;
  title: string;
  type: 'item' | 'collapse' | 'group';
  translate?: string;
  icon?: string;
  hidden?: boolean;
  url?: string;
  classes?: string;
  exactMatch?: boolean;
  external?: boolean;
  target?: boolean;
  breadcrumbs?: boolean;
  badge?: {
    title?: string;
    type?: string;
  };
  children?: NavigationItem[];
}

export const NavigationItems: NavigationItem[] = [
  {
    id: 'dashboard',
    title: 'Dashboard',
    type: 'group',
    icon: 'icon-navigation',
    children: [
      {
        id: 'default',
        title: 'Ventes',
        type: 'item',
        classes: 'nav-item',
        url: '/app/dashboard/default',
        icon: 'ti ti-dashboard',
        breadcrumbs: false,
      },
      {
        id: 'analytics',
        title: 'Locations',
        type: 'item',
        classes: 'nav-item',
        url: '/app/dashboard/analytics',
        icon: 'ti ti-device-analytics',
        breadcrumbs: false,
      },
    ],
  },
  {
    id: 'gestion_vente_location',
    title: 'Gestion de vente et location',
    type: 'group',
    icon: 'icon-navigation',
    children: [
      {
        id: 'gestion_vente_location',
        title: 'Vente de vécules',
        type: 'collapse',
        icon: 'ti ti-settings',
        children: [
          {
            id: 'gestion_vente_location',
            title: 'Création de vente',
            type: 'item',
            url: '/app/gestion-location-vente/creation-vente-vehicule',
            icon: 'ti ti-edit',
          },
          {
            id: 'gestion_vente_location',
            title: 'Liste des ventes',
            type: 'item',
            url: '/app/gestion-location-vente/liste-vente-vehicule',
            icon: 'ti ti-list',
          },
        ],
      },
      {
        id: 'gestion_vente_location',
        title: 'Location de vécules',
        type: 'collapse',
        icon: 'ti ti-settings',
        children: [
          {
            id: 'gestion_vente_location',
            title: 'Création de location',
            type: 'item',
            url: '/app/gestion-location-vente/creation-location-vehicule',
            icon: 'ti ti-edit',
          },
          {
            id: 'gestion_vente_location',
            title: 'Liste des locations',
            type: 'item',
            url: '/app/gestion-location-vente/liste-location-vehicule',
            icon: 'ti ti-list',
          },
        ],
      }
    ],
  },
  {
    id: 'gestion_des_partenaires',
    title: 'Gestion des partenaires',
    type: 'group',
    icon: 'icon-navigation',
    children: [
      {
        id: 'gestion_des_partenaires',
        title: 'Partenaires',
        type: 'collapse',
        icon: 'ti ti-settings',
        children: [
          {
            id: 'gestion_des_partenaires',
            title: 'Clients',
            type: 'item',
            url: '/app/gestion-partenaire/liste-client',
            icon: 'ti ti-list',
          },
          {
            id: 'gestion_des_partenaires',
            title: 'Fournisseurs',
            type: 'item',
            url: '/app/gestion-partenaire/liste-fournisseur',
            icon: 'ti ti-list',
          },
        ],
      }
    ],
  },

  {
    id: 'gestion_des_vehicules',
    title: 'Gestion des vehicules',
    type: 'group',
    icon: 'icon-navigation',
    children: [
      {
        id: 'gestion_des_vehicules',
        title: 'vehicules',
        type: 'collapse',
        icon: 'ti ti-settings',
        children: [
          {
            id: 'gestion_des_vehicules',
            title: 'Liste de véhicules',
            type: 'item',
            url: '/app/gestion-vehicule/Vehicules/Nos_vehicule',
            icon: 'ti ti-list-check',
          },
          {
            id: 'gestion_des_vehicules',
            title: 'Ajout de véhicules',
            type: 'item',
            url: '/app/gestion-vehicule/Vehicules/Ajout_vehicule',
            icon: 'ti ti-edit',
          },
        ],
      },
      {
        id: 'Gestion_des_Marques',
        title: 'Marques',
        type: 'collapse',
        icon: 'ti ti-settings',
        children: [
          {
            id: 'Ajoute de Marques',
            title: 'Ajout de marque',
            type: 'item',
            url: '/app/gestion-vehicule/Marques/Ajout_marque',
            icon: 'ti ti-edit',
          },
        ],
      },
      {
        id: 'Gestion_des_Models',
        title: 'Models',
        type: 'collapse',
        icon: 'ti ti-settings',
        children: [

          {
            id: 'Ajouter un Models',
            title: 'Ajout de Modèles',
            type: 'item',
            url: '/app/gestion-vehicule/Models/Ajout_models',
            icon: 'ti ti-edit',
          },
        ],
      },
      {
        id: 'Gestion_des_types',
        title: 'Types',
        type: 'collapse',
        icon: 'ti ti-settings',
        children: [

          {
            id: 'Ajouter_un_Type',
            title: 'Ajout de Type',
            type: 'item',
            icon: 'ti ti-edit',
            url: '/app/gestion-vehicule/Types/Ajout_types',
          },
        ],
      },
    ],
  },
  {
    id: 'Gestion_des_Chauffeurs',
    title: 'Gestion des Chauffeurs',
    type: 'group',
    icon: 'icon-navigation',
    children: [
      {
        id: 'Gestion_des_Chauffeurs',
        title: 'Chauffeurs',
        type: 'collapse',
        icon: 'ti ti-settings',
        children: [
      {
        id: 'Gestion_des_Chauffeurs',
        title: 'Ajouter des Chauffeurs',
        type: 'item',
        url: '/app/gestion-chauffeur/Ajout_Chauffeur',
        icon: 'ti ti-edit',
      },
    ],
      }
    ],
  },
  {
    id: 'Gestion_du_Carburant',
    title: 'Gestion du Carburant',
    type: 'group',
    icon: 'icon-navigation',
    children: [
      {
        id: 'Gestion_du_Carburant',
        title: 'Carburants',
        type: 'collapse',
        icon: 'ti ti-settings',
        children: [
      {
        id: 'Gestion_du_Carburant',
        title: 'Rechargement Effectuer',
        type: 'item',
        url: '/app/gestion-carburant/liste-rechargerment',
        icon: 'ti ti-edit',
      },
      {
        id: 'Gestion_du_Carburant',
        title: 'Fiche-rechargement',
        type: 'item',
        url: '/app/gestion-carburant/Fiche-de-Carburant',
        icon: 'ti ti-list-check',
      },
    ],}
    ],
  },
  {
    id: 'Gestion_de_la_maintenance',
    title: 'Gestion maintenance',
    type: 'group',
    icon: 'icon-navigation',
    children: [
      {
        id: 'Gestion_du_Carburant',
        title: 'Carburants',
        type: 'collapse',
        icon: 'ti ti-settings',
        children: [
      {
        id: 'Gestion_du_Carburant',
        title: 'Liste maintenance',
        type: 'item',
        url: '/app/gestion-maintenance/liste-maintenance',
        icon: 'ti ti-list-check',
      },
      {
        id: 'Gestion_du_Carburant',
        title: 'Fiche de maintenance',
        type: 'item',
        url: '/app/gestion-maintenance/Ajout_maintenance',
        icon: 'ti ti-list-check',
      },
    ],}
    ],
  },
  {
    id: 'Gestion_des_visites',
    title: 'Gestion visite',
    type: 'group',
    icon: 'icon-navigation',
    children: [
      {
        id: 'Liste des vistes',
        title: 'Liste visites',
        type: 'item',
        url: '/app/gestion-visite/liste-visite',
        icon: 'ti ti-user-check',
      },
      {
        id: 'Fiche_de_visite',
        title: 'Fiche de visite',
        type: 'item',
        url: '/app/gestion-visite/Ajout_visite',
        icon: 'ti ti-user-check',
      },
    ],
  },
  {
    id: 'Gestion_des_documents',
    title: 'Gestion documents',
    type: 'group',
    icon: 'icon-navigation',
    children: [
      {
        id: 'Liste des documents',
        title: 'Listes documents',
        type: 'item',
        url: '/app/gestion-documents/listes-documents',
        icon: 'ti ti-user-check',
      },
      {
        id: 'Fiche_de_document',
        title: 'Fiche documentation',
        type: 'item',
        url: '/app/gestion-documents/Ajout_document',
        icon: 'ti ti-user-check',
      },
    ],
  },
  {
    id: 'Gestion_des_Assurancess',
    title: 'Gestion des assurés',
    type: 'group',
    icon: 'icon-navigation',
    children: [
      {
        id: 'Liste des assurés',
        title: 'Liste assuré',
        type: 'item',
        url: '/app/gestion-assurance/Liste-des-assures',
        icon: 'ti ti-user-check',
      },
      {
        id: 'Fiche_de_assurance',
        title: 'Fiche assurance',
        type: 'item',
        url: '/app/gestion-assurance/Ajout_assure',
        icon: 'ti ti-user-check',
      },
    ],
  },
];
