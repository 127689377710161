import { Injectable } from "@angular/core";
import { CookieService } from 'ngx-cookie-service';
import * as CryptoJS from 'crypto-js';
import { BehaviorSubject } from "rxjs/internal/BehaviorSubject";

@Injectable({ providedIn: "root" })
export class SessionService {

  private loggedIn: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  get isLoggedIn() {
    return this.loggedIn.asObservable();
  }

  constructor() {
    if(this.getData("user")){
      this.loggedIn.next(true);
    }else{
      this.loggedIn.next(false);
    }
   }

  refresh(){
    if(this.getData("user")){
      this.loggedIn.next(true);
    }else{
      this.loggedIn.next(false);
    }
  }

  public saveData(key: string, value: string) {

    localStorage.setItem(key, value);
  }

  public getData(key: string) {
    let data = localStorage.getItem(key)|| "";
    return data;
  }
  public removeData(key: string) {
    localStorage.removeItem(key);
  }

  public clearData() {
    localStorage.clear();
  }

}
