import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { SessionService } from 'src/app/Pages/auth/services/session.service';
import { WorkflowActionReponse, WorkflowReponse, WorkflowStateReponse } from '../reponses/workflow.reponse';
import { WorkflowActionModele, WorkflowModele } from '../modeles/workflow.modele';


@Injectable({
  providedIn: 'root'
})
export class WorkflowService {
  private url: string = `${environment.BaseUrl}`;
  private key: string = `${environment.key}`;
  constructor(private http: HttpClient, private localStorageService: SessionService) { }
  private token: string = 'Bearer ' + this.localStorageService.getData('token');
  // Définition des options HTTP avec en-têtes
  private getHttpOptions(): { headers: HttpHeaders } {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept-Language': 'fr-FR',
        'Authorization': this.token,
        //'X-KEY': this.key,
      }),
    };
  }

  create(payload: WorkflowModele): Observable<WorkflowReponse> {
    return this.http.post<any>(`${this.url}/workflows`, payload, this.getHttpOptions());
  }
  createWorkflowState(payload: WorkflowActionModele): Observable<WorkflowReponse> {
    return this.http.post<any>(`${this.url}/workflows/actions`, payload, this.getHttpOptions());
  }

  update(payload: WorkflowModele, id: any): Observable<WorkflowReponse> {
    return this.http.put<any>(`${this.url}/workflows/${id}`, payload, this.getHttpOptions());
  }

  delete(id: any): Observable<WorkflowReponse> {
    return this.http.delete<any>(`${this.url}/workflows/${id}`, this.getHttpOptions());
  }

  deleteWorkflowAction(id: any): Observable<WorkflowActionReponse> {
    return this.http.delete<any>(`${this.url}/workflows/actions/${id}`, this.getHttpOptions());
  }

  getById(id: any): Observable<WorkflowReponse> {
    return this.http.get<any>(`${this.url}/workflows/${id}`, this.getHttpOptions());
  }

  getAll(query?: any): Observable<WorkflowReponse[]> {
    const url = query ? `${this.url}/workflows?${query}` : `${this.url}/workflows`;
    return this.http.get<any>(url, this.getHttpOptions());
  }
  getStateAll(query?: any): Observable<WorkflowStateReponse[]> {
    const url = query ? `${this.url}/workflows/states?${query}` : `${this.url}/workflows/states`;
    return this.http.get<any>(url, this.getHttpOptions());
  }

  getParamsWorkflowState(type: any): Observable<WorkflowStateReponse[]> {
    return this.http.get<any>(`${this.url}/parametres/type/${type}`, this.getHttpOptions());
  }



}
