<div class="auth-main">
  <div class="auth-wrapper v3">
    <div class="auth-form">
      <div class="card mt-5">
        <div class="card-body">
          <div class="row">
            <div class="d-flex justify-content-center">
              <div class="text-center">
                <h2 class="text-secondary mt-5"><b>Restorer votre mot de passe</b></h2>
                <p class="f-16 mt-3">S'il vous plait, Entre votre nouveau mot de passe.</p>
              </div>
            </div>
          </div>
          <div class="form-floating mt-2">
            <input type="password" class="form-control" id="password" placeholder="name@example.com" />
            <label for="password">Mot de passe</label>
          </div>
          <div class="form-floating mt-3">
            <input type="password" class="form-control" id="floatingInput" placeholder="name@example.com" />
            <label for="floatingInput">Confirmer votre mot de passe</label>
          </div>
          <div class="d-grid mt-4">
            <button type="button" class="btn btn-secondary p-2"> Restorer votre mot de passe </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
