<div class="auth-main">
  <div class="auth-wrapper v3">
    <div class="auth-form">
      <div class="card mt-5">
        <div class="card-body">
          <div class="row">
            <div class="d-flex justify-content-center">
              <div class="text-center">
                <h2 class="text-secondary mt-5"><b>Salut, Vérifier votre Email</b></h2>
                <p class="f-16 mt-3"> 
                  Nous vous avons envoyez les informations de restorations de votre mot de passe sur votre mail.</p>
              </div>
            </div>
          </div>
          <div class="d-grid mt-4">
            <button type="button" class="btn btn-secondary p-2"> Ouvrez votre Email </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
