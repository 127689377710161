import { Component, ElementRef, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EMPTY, Observable } from 'rxjs';
import { ClientReponse } from 'src/app/Pages/gestion-partenaire/client/reponse/client.reponse';
import { ClientService } from 'src/app/Pages/gestion-partenaire/client/service/client.service';
import { VehiculeReponse } from 'src/app/Pages/gestion-vehicule/vehicule/reponses/vehicule.reponse';
import { VenteVehiculeService } from '../../vente-vehicule/service/vente.vehicule.service';
import { VehiculeService } from 'src/app/Pages/gestion-vehicule/vehicule/services/vehicule.service';
import { VenteVehiculeReponse } from '../../vente-vehicule/reponse/vente.vehicule.reponse';
import { DetailCommande, VenteVehiculeModele } from '../../vente-vehicule/modeles/vente.vehicule.modele';
import { CommonModule } from '@angular/common';
import { NgSelectModule } from '@ng-select/ng-select';
import { SharedModule } from 'src/app/theme/shared/shared.module';
import { ChauffeurReponse } from 'src/app/Pages/gestion-chauffeur/reponse/chauffeur.reponse';
import { ChauffeurService } from 'src/app/Pages/gestion-chauffeur/service/chauffeur.service';

interface TodoItem {
  cId: any;
  vId: any;
  chId: any|null;
  msg: string;
  immatriculation: string;
  chauffeur: string;
  amount: string;
  total: string;
  dateDebut: string;
  dateFin: string;
  villeDepart: string;
  villeArrivee: string;
  montantChauffeur: string;

}
@Component({
  selector: 'app-creation-location-vehicule',
  standalone: true,
  imports: [CommonModule, NgSelectModule, SharedModule],
  templateUrl: './creation.location.vehicule.component.html',
  styleUrl: './creation.location.vehicule.component.scss'
})
export class CreationLocationVehiculeComponent implements OnInit {

  selectedOption: any;
  venteForm: FormGroup;
  isLoading: boolean = false;
  hasError: boolean = false;
  hasSuccess: boolean = false;
  errorMessage: any;
  successMessage: any;
  vehiculeList$: Observable<VehiculeReponse[]> = EMPTY;
  clientList$: Observable<ClientReponse[]> = EMPTY;
  chauffeurList$: Observable<ChauffeurReponse[]> = EMPTY;
  vehiculeSelected: VehiculeReponse = {} as VehiculeReponse;
  clientSelected: ClientReponse = {} as ClientReponse;
  chauffeurSelected: ChauffeurReponse = {} as ChauffeurReponse;
  // private props
  activatedVehicules: any[] = [];
  activatedChauffeurs: any[] = [];
  todoList: TodoItem[] = [];
  total = 0;
  totalRemse = 0;
  totalChauffeur = 0;

  // Constructor
  constructor(private modalService: NgbModal, private fb: FormBuilder, private vehiculeService: VehiculeService,
    private clientService: ClientService, private venteVehiculeService: VenteVehiculeService,private chauffeurService: ChauffeurService) {
    this.venteForm = this.fb.group({
      idClient: ['', [Validators.required]],
      idVehicule: ['', [Validators.required]],
      dateDebut: ['', [Validators.required]],
      dateFin: ['', [Validators.required]],
      villeDepart: ['', [Validators.required]],
      villeArrivee: ['', [Validators.required]],
      montant: ['', [Validators.required]],
      montantChauffeur: [0],
      idChauffeur: [null],
      tva: [0],
      remise: [0],
    });
  }
  ngOnInit(): void {

    this.vehiculeList$ = this.findVehicule();
    this.clientList$ = this.findClient();
    this.chauffeurList$ = this.findChauffeur();
    this.vehiculeList$.subscribe((vehicules: any[]) => {
      this.activatedVehicules = vehicules.filter(v => v.etat === 'ACTIVATED' && v.categorie === 'L_VEHICULE');
    });

    this.chauffeurList$.subscribe((chauffeurs: any[]) => {
      this.activatedChauffeurs = chauffeurs.filter(v => v.etat === 'ACTIVATED');
    });

    // Subscribe to changes in the 'tva' form control
    this.venteForm.get('tva')?.valueChanges.subscribe(() => {
      this.calculateTotalRemise();
    });

    // Optionally, subscribe to changes in the 'remise' form control if needed
    this.venteForm.get('remise')?.valueChanges.subscribe(() => {
      this.calculateTotalRemise();
    });
  }

  // Fonction pour sélectionner une valeur dans le ng-select
  selectVehicule(vehiculeReponse: VehiculeReponse): void {
    if (!this.isDisabled(vehiculeReponse.immatriculationProvisoire)) {
      this.venteForm.get('idVehicule')?.setValue(vehiculeReponse.idVehicule);
      this.venteForm.get('montant')?.setValue(vehiculeReponse.tarifVehicule.prixLocation);
      this.vehiculeSelected = vehiculeReponse;
    }

  }
  selectClient(clientReponse: ClientReponse): void {
    this.venteForm.get('idClient')?.setValue(clientReponse.idClient);
    this.clientSelected = clientReponse;
  }
  selectChauffeur(chauffeurReponse: ChauffeurReponse): void {
    this.venteForm.get('idChauffeur')?.setValue(chauffeurReponse.idChauffeur);
    this.chauffeurSelected = chauffeurReponse;
  }
  formatNumberWithSpace(value: number): string {
    if (value == null) {
      return '0'; // or any other default value
    }
    return value + ''.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
  }
  // private method
  addTodoList() {
    this.todoList.push({
      cId: this.clientSelected.idClient,
      vId: this.vehiculeSelected.idVehicule,
      immatriculation: this.vehiculeSelected.immatriculationProvisoire,
      msg: this.vehiculeSelected.immatriculationProvisoire + ' - ' + this.vehiculeSelected.marque?.libelle + ' - ' + this.vehiculeSelected.modele?.libelle + ' - Client : ' + this.clientSelected.nom + ' ' + this.clientSelected.prenoms,
      amount: this.venteForm.get('montant')?.value,
      total: this.venteForm.get('montant')?.value,
      dateDebut: this.venteForm.get('dateDebut')?.value,
      dateFin: this.venteForm.get('dateFin')?.value,
      villeDepart: this.venteForm.get('villeDepart')?.value,
      villeArrivee: this.venteForm.get('villeArrivee')?.value,
      chId: this.venteForm.get('idChauffeur')?.value,
      montantChauffeur: this.venteForm.get('montantChauffeur')?.value,
      chauffeur: this.chauffeurSelected.nom + ' ' + this.chauffeurSelected.prenoms,
    });

    this.calculateTotal();
    this.venteForm.reset();
  }
  calculateTotalRemise(): void {
    // Ensure the values from the form are numbers, defaulting to 0 if they are null or undefined
    const tvaValue = this.venteForm.get('tva')?.value || 0;
    const remiseValue = this.venteForm.get('remise')?.value || 0;

    this.totalChauffeur = this.todoList.reduce((sum, item) => {
      const itemTotal = parseFloat(item.montantChauffeur) || 0;
      return sum + itemTotal;
    }, 0);
    // Calculate the total from the todoList, ensuring each item's total is a valid number
    const totalFromTodoList = this.todoList.reduce((sum, item) => {
      const itemTotal = parseFloat(item.total) || 0;
      return sum + itemTotal;
    }, 0);
    if (tvaValue > 0) {
      this.totalRemse = totalFromTodoList + (totalFromTodoList * (tvaValue / 100)) - remiseValue+this.totalChauffeur;
    } else {
      this.totalRemse = totalFromTodoList - remiseValue+this.totalChauffeur;
    }


  }

  calculateTotal(): void {
    this.total = this.todoList.reduce((sum, item) => sum + parseFloat(item.total), 0);
    this.calculateTotalRemise();
  }

  isDisabled(immatriculation: string): boolean {
    return this.todoList.some(todo => todo.immatriculation === immatriculation);
  }
  removeItem(vId: any): void {
    this.todoList = this.todoList.filter(item => item.vId !== vId);
    this.calculateTotal();
  }



  printPage() {
    const link2 = document.createElement('link');
    link2.innerHTML =
      '<style>@media print{*,::after,::before{text-shadow:none!important;box-shadow:none!important}a:not(.btn){text-decoration:none}abbr[title]::after{content:" ("attr(title) ")"}pre{white-space:pre-wrap!important}blockquote,pre{border:1px solid #adb5bd;page-break-inside:avoid}thead{display:table-header-group}img,tr{page-break-inside:avoid}h2,h3,p{orphans:3;widows:3}h2,h3{page-break-after:avoid}@page{size:a3}body{min-width:992px!important}.container{min-width:992px!important}.page-header,.coded-navbar,.coded-mob-header,.coded-header,.menu-styler,.modal,.navbar{display:none}.coded-main-container{top:0;margin-left:0;}.invoice-contact{padding-top:0;}@page,.card-body,.card-header,body,.coded-content{padding:0;margin:0}.badge{border:1px solid #000}.table{border-collapse:collapse!important}.table td,.table th{background-color:#fff!important}.table-bordered td,.table-bordered th{border:1px solid #dee2e6!important}.table-dark{color:inherit}.table-dark tbody+tbody,.table-dark td,.table-dark th,.table-dark thead th{border-color:#dee2e6}.table .thead-dark th{color:inherit;border-color:#dee2e6}}</style>';

    document.getElementsByTagName('head')[0].appendChild(link2);
    window.print();
  }



  onSubmit() {
   // this.isLoading = true;
    this.hasError = false;
    this.hasSuccess = true;
    if (this.todoList.length > 0) {

      const details = this.todoList.map((item) => new DetailCommande(
        item.amount,
        1,
        item.vId,
        item.dateDebut,
        item.dateFin,
        item.chId,
        item.montantChauffeur,
        item.villeDepart,
        item.villeArrivee
      ));
      const payload = new VenteVehiculeModele(
        this.todoList.reduce((sum, item) =>
          sum + Number(item.amount) + Number(item.montantChauffeur), 0
        ),
        "L_VEHICULE",
        0,
        this.todoList[0].cId,
        this.venteForm.get('tva')?.value || 0,
        this.venteForm.get('remise')?.value || 0,
        details
      );

      this.venteVehiculeService.create(payload).subscribe(
        (response: VenteVehiculeReponse) => {
          this.isLoading = false;
          this.hasSuccess = true;
          this.successMessage = 'Location ajoutée avec succès.';
          this.todoList = [];
          this.venteForm.reset();
        },
        (error: any) => {
          this.hasError = true;
          this.isLoading = false;
          this.errorMessage = error.error.message;
          console.error('Erreur lors de la création de la Location', error);
        }
      );
    } else {
      console.log('Le formulaire est invalide');
    }
  }

  findVehicule(): Observable<VehiculeReponse[]> {
    return this.vehiculeService.getAll()
  }

  findClient(): Observable<ClientReponse[]> {
    return this.clientService.getAll();
  }

  findChauffeur(): Observable<ChauffeurReponse[]> {
    return this.chauffeurService.getAll();
  }

}

