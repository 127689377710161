<!-- [ Sidebar Menu ] start -->
<nav class="">
  <div class="navbar-wrapper">
    <div class="m-header">
      <!-- [ Header TopBar ] start -->
      <a (click)="returnHome()" class="b-brand">
        <!-- ========   change your logo hear   ============ -->
        <img src="assets/images/logo.svg" alt="logo" class="logo logo-lg" />
        <img src="assets/images/Logo-small.svg" alt="small menu logo" class="logo logo-sm" />
      </a>
    </div>
    <app-nav-content
      (NavCollapsedMob)="navCollapseMob()"
      (SubmenuCollapse)="navSubmenuCollapse()"
      class="scroll-div w-100 compact"
    ></app-nav-content>
  </div>
</nav>
<!-- [ Sidebar Menu ] end -->
