import { CommonModule } from '@angular/common';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from 'src/app/theme/shared/shared.module';
import { FileUploadValidators, FileUploadModule } from '@iplab/ngx-file-upload';
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MarqueModele } from '../modeles/marque.modele';
import { MarqueService } from '../services/marque.service';
import { MarqueReponse } from '../reponses/marque.reponse';
import { Observable } from 'rxjs';
import { ListeMComponent } from '../liste-m/liste-m.component';


@Component({
  selector: 'app-creation-m',
  standalone: true,
  imports: [CommonModule,ListeMComponent, SharedModule, RouterModule, FileUploadModule, ReactiveFormsModule],
  templateUrl: './creation-m.component.html',
  styleUrl: './creation-m.component.scss'
})
export class CreationMComponent implements OnInit{

  marqueForm: FormGroup;
  isLoading: boolean = false;
  hasError: boolean = false;
  hasSuccess: boolean = false;
  errorMessage: any;
  successMessage: any;

  constructor(private fb: FormBuilder, private marqueService: MarqueService) {
    this.marqueForm = this.fb.group({
      libelle: ['', [Validators.required, Validators.minLength(1)]],
      description: ['', Validators.required]
    });
  }

  // Méthode appelée à la soumission du formulaire
  onSubmit() {
    this.isLoading = true;
    if (this.marqueForm.valid) {
      const payload: MarqueModele = this.marqueForm.value;
      this.createMarque(payload).subscribe(
        (response: MarqueReponse) => {
          this.isLoading = false;
          this.hasSuccess = true;
          this.successMessage = 'Marque de véhicule ajouté avec succès.';
          this.marqueForm.reset();
        },
        (error: any) => {
          this.hasError = true;
          this.isLoading = false;
          this.errorMessage = error.error.message;
          console.error('Erreur lors de la création de la marque', error);
        }
      );
    } else {
      console.log('Le formulaire est invalide');
    }
  }

  // Méthode pour envoyer la requête
  createMarque(payload: MarqueModele): Observable<MarqueReponse> {
    return this.marqueService.create(payload);
  }

  ngOnInit(): void {

  }
  @Output() onCloseModel = new EventEmitter();
//Form construct


onClose(){
  this.onCloseModel.emit(false)
};



  // upload image private Props
//   private filesControl = new UntypedFormControl(null, FileUploadValidators.filesLimit(2));

//   demoForm = new UntypedFormGroup({
//     files: this.filesControl
//   });

//   // private method
//   toggleStatus() {
//     this.filesControl.disabled ? this.filesControl.enable() : this.filesControl.disable();
// }
}
