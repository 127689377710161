<div class="row">
    <!-- [ sample-page ] start -->
    <div class="col-sm-12">
      <div class="card">
        <div class="card-header pb-0">
          <ul ngbNav aria-busy="true" #nav="ngbNav" class="nav-tabs nav profile-tabs">
            <li class="nav-item" [ngbNavItem]="1">
              <a href="javascript:" ngbNavLink class="nav-link"> <i class="material-icons-two-tone me-2">account_circle</i>Profile </a>
              <ng-template ngbNavContent>
                <div class="row">
                  <div class="col-md-4">
                    <div class="card border">
                      <div class="card-header">
                        <div class="media align-items-center">
                          <img class="img-radius img-fluid wid-40" src="assets/images/user/avatar-1.jpg" alt="User image" />
                          <div class="media-body mx-3">
                            <h4 class="mb-1">John Doe</h4>
                            <h6 class="text-muted mb-0">UI/UX Designer</h6>
                          </div>
                          <div class="badge bg-primary rounded-pill text-base"> Pro </div>
                        </div>
                      </div>
                      <div class="card-body px-2 pb-0">
                        <div class="list-group list-group-flush">
                          @for (list of infoList; track list) {
                            <a href="javascript:" class="list-group-item list-group-item-action">
                              <div class="media align-items-center">
                                <i class="material-icons-two-tone f-20">{{ list.icon }}</i>
                                <div class="media-body mx-3">
                                  <h5 class="m-0">{{ list.user }}</h5>
                                </div>
                                <small class="text-truncate">{{ list.text }}</small>
                              </div>
                            </a>
                          }
                        </div>
                      </div>
                      <div class="card-body">
                        <div class="row text-center">
                          @for (task of social; track task) {
                            <div class="col-4">
                              <h3 class="mb-0">{{ task.total }}</h3>
                              <small class="text-muted">{{ task.user }}</small>
                            </div>
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-8">
                    <app-card cardTitle="About Me">
                      <p class="mb-4">
                        Hello,I’m Anshan Handgun Creative Graphic Designer & User Experience Designer based in Website, I create digital
                        Products a more Beautiful and usable place. Morbid accusant ipsum. Nam nec tellus at.
                      </p>
                      <h5>Personal Details</h5>
                      <hr class="my-3" />
                      <div class="table-responsive">
                        <table class="table table-borderless">
                          <tbody>
                            @for (task of taskList; track task) {
                              <tr>
                                <td
                                  ><b class="text-header">{{ task.title }}</b></td
                                >
                                <td>:</td>
                                <td>{{ task.name }}</td>
                              </tr>
                            }
                          </tbody>
                        </table>
                      </div>
                    </app-card>
                    <app-card cardTitle="Education">
                      @for (task of education; track task) {
                        <div class="row align-items-center mb-3">
                          <div class="col-sm-3">
                            <h5>{{ task.year }}</h5>
                            <span>{{ task.course }}</span>
                          </div>
                          <div class="col-sm-9">
                            <h6>{{ task.title }}</h6>
                            <p>{{ task.text }}</p>
                          </div>
                        </div>
                      }
                    </app-card>
                    <app-card cardTitle="Employment">
                      <div class="row align-items-center mb-3">
                        <div class="col-sm-3">
                          <h5>Current</h5>
                          <span>Senior</span>
                        </div>
                        <div class="col-sm-9">
                          <h6>Senior UI/UX designer</h6>
                          <p class="mb-1">
                            Perform task related to project manager with the 100+ team under my observation. Team management is key role in
                            this company.
                          </p>
                          <span class="text-muted">Microsoft, TX, USA</span>
                        </div>
                      </div>
                      <div class="row align-items-center mb-3">
                        <div class="col-sm-3">
                          <h5>2017-2019</h5>
                          <span>Junior</span>
                        </div>
                        <div class="col-sm-9">
                          <h6>Trainee cum Project Manager</h6>
                          <span class="text-muted">Microsoft, TX, USA</span>
                        </div>
                      </div>
                    </app-card>
                    <app-card cardTitle="skills">
                      <div class="row align-items-center mt-3">
                        <div class="col-md-6 mb-3">
                          <h6 class="m-b-10 text-muted">Web Designer</h6>
                          <div class="row align-items-center">
                            <div class="col">
                              <div class="progress" style="height: 4px">
                                <div class="progress-bar bg-primary" style="width: 70%"></div>
                              </div>
                            </div>
                            <div class="col-auto">
                              <h6 class="m-b-0">70%</h6>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6 mb-3">
                          <h6 class="m-b-10 text-muted">Graphic Designer</h6>
                          <div class="row align-items-center">
                            <div class="col">
                              <div class="progress" style="height: 4px">
                                <div class="progress-bar bg-primary" style="width: 85%"></div>
                              </div>
                            </div>
                            <div class="col-auto">
                              <h6 class="m-b-0">80%</h6>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6 mb-3">
                          <h6 class="m-b-10 text-muted">UX Researcher</h6>
                          <div class="row align-items-center">
                            <div class="col">
                              <div class="progress" style="height: 4px">
                                <div class="progress-bar bg-secondary" style="width: 85%"></div>
                              </div>
                            </div>
                            <div class="col-auto">
                              <h6 class="m-b-0">3.2</h6>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6 mb-3">
                          <h6 class="m-b-10 text-muted">HTML</h6>
                          <div class="row align-items-center">
                            <div class="col">
                              <div class="progress" style="height: 4px">
                                <div class="progress-bar bg-primary" style="width: 45%"></div>
                              </div>
                            </div>
                            <div class="col-auto">
                              <h6 class="m-b-0">45%</h6>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6 mb-3">
                          <h6 class="m-b-10 text-muted">Wordpress</h6>
                          <div class="row align-items-center">
                            <div class="col">
                              <div class="progress" style="height: 4px">
                                <div class="progress-bar bg-secondary" style="width: 25%"></div>
                              </div>
                            </div>
                            <div class="col-auto">
                              <h6 class="m-b-0">25%</h6>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6 mb-3">
                          <h6 class="m-b-10 text-muted">PHP</h6>
                          <div class="row align-items-center">
                            <div class="col">
                              <div class="progress" style="height: 4px">
                                <div class="progress-bar bg-primary" style="width: 65%"></div>
                              </div>
                            </div>
                            <div class="col-auto">
                              <h6 class="m-b-0">65%</h6>
                            </div>
                          </div>
                        </div>
                      </div>
                    </app-card>
                  </div>
                </div>
              </ng-template>
            </li>
          </ul>
        </div>
        <div class="card-body">
          <div [ngbNavOutlet]="nav" class="mt-2"></div>
        </div>
      </div>
    </div>
    <!-- [ sample-page ] end -->
  </div>
  